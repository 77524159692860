import { PermissionOptions } from 'constants/Administrators';
import { BenefitStatus } from 'store/benefits';
import { EmbeddedCustomCategory } from 'store/custom-categories';
import { ExpenseStatus } from 'store/expenses';
import { DateRange, PaginationFilters } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { z } from 'zod';
import { AssignmentStatus } from 'store/assignments';
import { ContentLanguage, TimeUnit } from 'types/general';
import {
  employeeJobTitlesFiltersSchema,
  employeesFiltersSchema,
} from './employees.schemas';

export const employmentStatuses = [
  'recentlyEnded',
  'ended',
  'active',
  'endsSoon',
  'inactive',
] as const;
export type EmploymentStatus = (typeof employmentStatuses)[number];

export const employeeStatuses = ['active', 'inactive'] as const;
export type EmployeeStatus = (typeof employeeStatuses)[number];

export const employmentTypes = ['fullTime', 'partTime', 'intern'] as const;
export type EmploymentType = (typeof employmentTypes)[number];

export interface BaseEmployee {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  employmentStatus: EmploymentStatus;
  birthday: string | null;
  isAppUser: boolean;
  hiredAt: string;
  customCategories: EmbeddedCustomCategory[];
}

export interface Employee {
  id: string;
  parentCompanyId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  dateOfBirth: string | null;
  internalComment: string;
  personalCode: string;
  jobTitle: string;
  employeeId: string;
  externalId: string;
  hireDate: string | null;
  capacity: EmploymentType;
  endDate: string | null;
  startDate: string | null;
  endTime: string | null;
  startDateTZ: number;
  endDateTZ: number;
  startTime: string | null;
  userId: string;
  companyId: string;
  departmentId: string;
  language: Uppercase<ContentLanguage>;
  status: EmployeeStatus;
  temporaryEmployment: boolean;
  employeeToCategories: Array<{
    employeeCategoryId: string;
    employeeCategoryValueId: string;
    employeeId: string;
    id: string;
  }>;
  permission: PermissionOptions;
  employmentDate: string | null;
  employmentDateTZ: number;
  employmentTime: string;
  employmentDuration: Partial<Record<TimeUnit, number>> | null;
  user: {
    lastActionDate: string;
  } | null;
}

export interface EmployeeJobTitle {
  name: string;
}

export interface EmployeeWalletBenefit {
  id: string;
  name: string;
  status: BenefitStatus;
  assignmentStatus: AssignmentStatus;
  investment: number;
  expenses: number;
  remaining: number;
}

export interface EmployeeWalletRecognitions {
  totalPoints: number;
  totalCurrency: number;
  spentPoints: number;
  spentCurrency: number;
  availablePoints: number;
  availableCurrency: number;
  expiredPoints: number;
  expiredCurrency: number;
}

export type EmployeesFilters = z.infer<typeof employeesFiltersSchema>;

export type EmployeeJobTitlesFilters = z.infer<
  typeof employeeJobTitlesFiltersSchema
>;

export interface EmployeeExpensesFilters extends PaginationFilters {
  date: DateRange | undefined;
  source: string[];
  categories: ShopItemCategory[];
  status: ExpenseStatus[];
}

export interface EmployeeWalletBenefitsFilters extends PaginationFilters {
  benefits: string[];
  status: BenefitStatus[];
}
