import {
  InfiniteList,
  Loader,
  Menu,
  Modal,
  Tabs,
  Typography,
} from 'melp-design/components';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { sum } from 'utils/general';
import {
  EMOJI_BY_REACTION,
  Reaction,
  ReactionsVariant,
  useInfiniteReactions,
} from 'store/reactions';
import { Stack } from '@mui/material';

const TABS_COUNT = 4;

type TabsValue = ReactionsVariant | 'all';

interface Props extends ModalProps {
  hostId: string;
}

export const ReactionsModal: FC<Props> = ({ hostId, closeModal }) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<TabsValue>('all');

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteReactions(
    hostId,
    {
      reaction: tab === 'all' ? null : tab,
    },
  );

  const reactions = useMemo<{
    items: Reaction[];
    summary: Array<{ emoji: TabsValue; count: number }>;
  } | null>(() => {
    if (!data) return null;

    const summary = data.pages[0]?.summary;

    if (!summary) return null;

    return {
      items: (data.pages.map(({ items }) => items) ?? []).flat(),
      summary: [
        { emoji: 'all', count: sum(...summary.map((s) => s.count)) },
        ...summary,
      ],
    };
  }, [data]);

  return (
    <Modal.Content
      header={
        isLoading ? (
          <Loader size={25} />
        ) : reactions ? (
          <Stack flexDirection="row" gap={1}>
            <Tabs
              value={tab}
              items={reactions.summary
                .filter((_, i) => i < TABS_COUNT)
                .map((reaction) => ({
                  label: `${
                    reaction.emoji === 'all'
                      ? t('common.all')
                      : EMOJI_BY_REACTION[reaction.emoji]
                  } ${reaction.count}`,
                  value: reaction.emoji,
                }))}
              onChange={setTab}
            />
            <Menu
              label={t('sidebar.more')}
              selectable
              items={reactions.summary
                .filter((_, i) => i >= TABS_COUNT)
                .map((reaction) => ({
                  label: `${
                    reaction.emoji === 'all'
                      ? t('common.all')
                      : EMOJI_BY_REACTION[reaction.emoji]
                  } ${reaction.count}`,
                  value: reaction.emoji,
                  onClick: () => setTab(reaction.emoji),
                }))}
            />
          </Stack>
        ) : null
      }
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('buttons.ok'),
        },
      ]}
      dividers
    >
      <InfiniteList
        data={reactions?.items.map((reaction, i) => ({
          ...reaction,
          id: String(i),
        }))}
        renderItem={(reaction) => (
          <Typography variant="p1" sx={{ width: '100%' }}>
            {EMOJI_BY_REACTION[reaction.reaction]} {reaction.fullName}
          </Typography>
        )}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
      />
    </Modal.Content>
  );
};
