import { convertEmbeddedReactions } from 'store/reactions';
import { IComment } from 'types/income';
import { Comment } from './comments.types';

export const convertComment = (comment: IComment): Comment => {
  return {
    id: comment.id,
    status: comment.status,
    date: comment.date,
    content: comment.content,
    owner: comment.employeeFullName
      ? {
          type: 'employee',
          id: comment.employeeId ?? '',
          fullName: comment.employeeFullName,
        }
      : comment.adminFullName
      ? {
          type: 'admin',
          id: comment.adminId ?? '',
          fullName: comment.adminFullName,
        }
      : null,
    subject: {
      id: comment.subject.id,
      entity: comment.subject.entity,
      title: comment.subject.title,
    },
    reactions: convertEmbeddedReactions({
      summary: comment.reactions,
      selected: comment.selectedEmoji,
    }),
  };
};

export const convertComments = (comments: IComment[]): Comment[] => {
  return comments.map(convertComment);
};
