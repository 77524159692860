import { Modal, TextField } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useUpdateComment } from 'store/comments';
import { usePredefinedToasts } from 'utils/Toast';
import { Stack } from '@mui/material';

interface Values {
  comment: string;
}

interface Props extends ModalProps {
  commentId: string;
  initialComment: string;
}

export const CommentEditModal: FC<Props> = ({
  commentId,
  initialComment,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      comment: initialComment,
    },
  });

  const { mutate: updateComment, isLoading } = useUpdateComment();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateComment(
      { commentId, data: { content: values.comment } },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
          closeModal();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('clients.comment')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isLoading,
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                rows={5}
                required
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
