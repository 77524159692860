import { ComponentProps } from 'react';
import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { APP } from 'config';
import { Typography } from 'melp-design/components';
import { Dropdown } from '../../icons';
import { Colors, Effects } from '../../style';
import Button from '../ButtonOld/Button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagesList: {
    '& > *': {
      marginRight: 1,

      '&:first-child': {
        marginRight: 13,
      },

      '&:last-child': {
        marginLeft: 12,
        marginRight: 0,
      },
    },
  },
  backIcon: {
    transform: 'rotate(90deg)',
  },
  nextIcon: {
    transform: 'rotate(-90deg)',
  },
  pageButton: {
    padding: '5px 7px',
  },
  pageButtonActive: {
    color: Colors.black,
    textDecoration: 'underline',
  },
  pageSizeLabel: {
    '&:after': {
      content: '":"',
    },
  },
  pageSizeDropdown: {
    borderRadius: 6,
    borderColor: Colors.elementsGrey,
    marginLeft: 10,
  },
  dropdownRoot: {
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.4',
  },
  outlined: {
    '&.MuiSelect-outlined': {
      paddingRight: 25,
    },
  },
  dropdownIcon: {
    color: Colors.grey,
    width: 10,
    height: 10,
    top: 'calc(50% - 5px)',
    right: 10,
  },
  dropdownMenuItem: {
    // using !important to override another !important that was used to override default MUI style
    padding: '5px 10px !important',
    display: 'flex',
    gap: 10,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: Colors.screenBackgroundGrey,
    },
    '&::after': {
      display: 'none',
    },
  },
  dropdownPaper: {
    boxShadow: Effects.smallElementsShadow,
    border: `1px solid ${Colors.elementsGrey}`,
    borderRadius: 6,
  },
  dropdownMenuList: {
    padding: 5,
    '& > *:not(:last-child)': {
      marginBottom: 2,
    },
  },
}));

export interface PaginationProps {
  /**
   * Current page number
   */
  page: number;
  /**
   * Number of data items per page
   */
  pageSize: number;
  /**
   * Total number of data items
   */
  count: number;
  /**
   * Called when the page number or page size is changed. Changing page size
   * will lead to page being set to 1.
   */
  onChange: (page: number, pageSize: number) => void;
  /**
   * Options to display in page size dropdown
   */
  pageSizeOptions?: number[] | Readonly<number[]>;
  showPageSize?: boolean;
}

const Pagination = ({
  pageSizeOptions = APP.pagination.defaults.pageSize,
  showPageSize = true,
  ...props
}: PaginationProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const numOfPages = Math.ceil(props.count / props.pageSize);

  const showPages = props.count > props.pageSize;

  return (
    <div className={classes.root}>
      <div>
        {showPageSize ? (
          <>
            <label htmlFor="page-size-dropdown">
              <Typography
                variant="p2"
                color="textSecondary"
                component="span"
                className={classes.pageSizeLabel}
              >
                {t('table.pageSize')}
              </Typography>
            </label>
            <Select
              id="page-size-dropdown"
              variant="outlined"
              value={props.pageSize}
              onChange={(e) => {
                const newPageSize = e.target.value as number;
                props.onChange(1, newPageSize);
              }}
              className={classes.pageSizeDropdown}
              classes={{
                select: classes.dropdownRoot,
                outlined: classes.outlined,
                icon: classes.dropdownIcon,
              }}
              IconComponent={Dropdown}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                classes: {
                  paper: classes.dropdownPaper,
                  list: classes.dropdownMenuList,
                },
              }}
            >
              {pageSizeOptions.map((optionValue) => (
                <MenuItem
                  key={optionValue}
                  value={optionValue}
                  className={classes.dropdownMenuItem}
                >
                  {optionValue}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : null}
      </div>
      <div>
        {showPages && (
          <MuiPagination
            page={props.page}
            count={numOfPages}
            onChange={(_, newPage) => props.onChange(newPage, props.pageSize)}
            classes={{
              ul: classes.pagesList,
            }}
            renderItem={(item) => {
              const commonProps: ComponentProps<typeof Button> = {
                variant: 'outlined',
                textColor: 'grey',
                iconOnly: true,
                onClick: item.onClick,
                disabled: item.disabled,
              };
              switch (item.type) {
                case 'previous':
                  return (
                    <Button {...commonProps}>
                      <Dropdown className={classes.backIcon} />
                    </Button>
                  );
                case 'next':
                  return (
                    <Button {...commonProps}>
                      <Dropdown className={classes.nextIcon} />
                    </Button>
                  );
                case 'page':
                  return (
                    <Button
                      variant="text"
                      textColor="grey"
                      size="small"
                      className={clsx(
                        classes.pageButton,
                        item.selected && classes.pageButtonActive,
                      )}
                      onClick={item.onClick}
                    >
                      {item.page}
                    </Button>
                  );
                case 'end-ellipsis':
                case 'start-ellipsis':
                  return (
                    <Typography
                      variant="p2"
                      component="span"
                      color="textSecondary"
                    >
                      ...
                    </Typography>
                  );
                default:
                  return <PaginationItem {...item} />;
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Pagination;
