import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { ContentLanguage } from 'types/general';
import { TableDataResponse } from 'types/Table';
import {
  IListCompany,
  IDepartment,
  IParentCompanyWithStats,
  ICompany,
  ICompanyContact,
} from 'types/income';
import { ParentCompaniesFilters } from './companies.types';

interface GetCompany {
  companyId: string;
}

interface CreateCompany {
  name: string;
  code: string;
  country: string;
  logoId: string | null;
  additionalInfo: string;
  companyGroupIds: string[];
  supportedLanguages: Uppercase<ContentLanguage>[];
  activationDate: string | null;
  deactivationDate: string | null;
  departments: Array<{ id: string | null; name: string; hidden: boolean }>;
  settings: {
    externalRecognitionProvider: boolean;
    requireShippingInfoForMerchandise: boolean;
    canGiveRecognition: boolean;
  };
}

interface UpdateCompany {
  companyId: string;
  data: Partial<CreateCompany>;
}

interface UpdateCompanySettings {
  companyId: string;
  data: Partial<CreateCompany['settings']>;
}

interface GetCompanyContacts {
  companyId: string;
}

interface CreateCompanyContact {
  companyId: string;
  data: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string | null;
  };
}

interface UpdateCompanyContact {
  contactId: string;
  data: Partial<CreateCompanyContact['data']>;
}

interface DeleteCompanyContact {
  contactId: string;
}

interface GetParentCompanies {
  filters: Partial<ParentCompaniesFilters>;
}

interface UpdateParentCompanySettings {
  language: Uppercase<ContentLanguage>;
  isPersonalCodeRequired: boolean;
  isEmailVisibleInternally: boolean;
}

export const loaders = {
  getCompanies: () => {
    return api<TableDataResponse<IListCompany>>({
      url: Endpoints.clientAdmin.companies.root,
    });
  },
  getCompany: ({ companyId }: GetCompany) => {
    return api<ICompany>({
      url: Endpoints.clientAdmin.companies.byId(companyId).root,
    });
  },
  createCompany: (data: CreateCompany) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.companies.create,
      data: {
        ...data,
        settings: data.settings ? { ...data.settings } : undefined,
      },
      method: 'post',
    });
  },
  updateCompany: ({ companyId, data }: UpdateCompany) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.companies.byId(companyId).root,
      data: {
        ...data,
        settings: data.settings ? { ...data.settings } : undefined,
      },
      method: 'patch',
    });
  },
  updateCompanySettings: ({ companyId, data }: UpdateCompanySettings) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.companies.byId(companyId).settings,
      data,
      method: 'patch',
    });
  },
  getCompanyContacts: ({ companyId }: GetCompanyContacts) => {
    return api<ICompanyContact[]>({
      url: Endpoints.clientAdmin.companies.byId(companyId).contacts.root,
    });
  },
  createCompanyContact: ({ companyId, data }: CreateCompanyContact) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.companies.byId(companyId).contacts.root,
      data: { ...data },
      method: 'post',
    });
  },
  updateCompanyContact: ({ contactId, data }: UpdateCompanyContact) => {
    return api({
      url: Endpoints.clientAdmin.companies.contacts.byId(contactId).root,
      data: { ...data },
      method: 'patch',
    });
  },
  deleteCompanyContact: ({ contactId }: DeleteCompanyContact) => {
    return api({
      url: Endpoints.clientAdmin.companies.contacts.byId(contactId).root,
      method: 'delete',
    });
  },
  getParentCompanies: ({ filters }: GetParentCompanies) => {
    return api<TableDataResponse<IParentCompanyWithStats>>({
      url: Endpoints.parentCompany,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
      },
    });
  },
  getDepartments: () => {
    return api<
      Array<{
        companies: Array<{
          name: string;
          id: string;
          departments: IDepartment[];
        }>;
      }>
    >({
      url: Endpoints.clientAdmin.companies.departments,
      params: {
        module: 'benefits',
        permissions: 'edit',
      },
    });
  },
  updateParentCompanySettings: ({
    language,
    isPersonalCodeRequired,
    isEmailVisibleInternally,
  }: UpdateParentCompanySettings) => {
    return api({
      url: Endpoints.updateDefaultLanguage,
      method: 'patch',
      data: {
        defaultLanguage: language,
        isPersonalCodeRequiredForLogin: isPersonalCodeRequired,
        isEmailVisibleInternally,
      },
    });
  },
};
