import { Stack } from '@mui/material';
import { FC } from 'react';
import { LinkAsButton, Typography } from 'melp-design/components';

interface Props {
  count: number;
  onClick?: () => void;
}

export const CommentsPreview: FC<Props> = ({ count, onClick }) => {
  const preview = (
    <Stack flexDirection="row" alignItems="center">
      <Typography>💬 </Typography>
      <Typography color="textSecondary">{count}</Typography>
    </Stack>
  );

  return onClick ? (
    <LinkAsButton label={preview} onClick={onClick} />
  ) : (
    <>{preview}</>
  );
};
