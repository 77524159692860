import { z } from 'zod';
import { BrandProvider } from 'store/brands';
import { Translations } from 'types/Common';
import { ContentLanguage, Currency } from 'types/general';
import { adminBrandCardsFiltersSchema } from './admin-brand-cards.schemas';

export const adminBrandCardStatuses = [
  'enabled',
  'disabled_by_melp',
  'disabled_by_provider',
] as const;
export type AdminBrandCardStatus = (typeof adminBrandCardStatuses)[number];

export const adminBrandCardPriceTypes = ['fixed', 'range'] as const;
export type AdminBrandCardPriceType = (typeof adminBrandCardPriceTypes)[number];

export interface AdminBaseBrandCard {
  id: string;
  sequence: number;
  externalId: string;
  status: AdminBrandCardStatus;
  brand: { id: string; name: string } | null;
  countries: string[];
  provider: BrandProvider;
  ordersCount: number;
  priceType: AdminBrandCardPriceType;
  language: Uppercase<ContentLanguage>;

  faceValues: number[];
  faceValuesLabels: string[];
  faceValueMin: number;
  faceValueMax: number;
  faceValueCurrency: Currency;

  purchasePriceDiscountPercentage: number;
  purchasePriceDiscountFee: number;
  purchasePriceCurrency: Currency;

  melpFeeEuro: number;
  melpFeePercentage: number;

  createdAt: string;
  updatedAt: string | null;
}

export interface AdminBrandCard extends AdminBaseBrandCard {
  supportedLanguages: Uppercase<ContentLanguage>[];
  content: Translations<{
    label: string;
    description: string;
    terms: string;
    redeemInstructions: string;
  }>;
  prices: Array<{
    faceValue: number;
    content: Translations<{ faceValuesLabel: string }>;
  }>;
  raw: string;
}

export type AdminBrandCardsFilters = z.infer<
  typeof adminBrandCardsFiltersSchema
>;
