import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IBaseBrand,
  IRecognitionBrandsConfig,
  IRecognitionBrandWithSelection,
} from 'types/income';
import { getSorting } from 'utils/general';
import { BenefitBrandsFilters } from './benefit-brands.types';

const getFilters = (filters: Partial<BenefitBrandsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    type: filters.variant,
    brandIds: filters.brands,
    provider: filters.providers,
    country: filters.countries,
    primaryCategory: filters.primaryCategories,
    priceFrom: filters.price?.from,
    priceTo: filters.price?.to,
    isWhitelisted:
      filters.selection === 'selected'
        ? true
        : filters.selection === 'not-selected'
        ? false
        : undefined,
  },
});

interface GetBrands {
  benefitId: string;
  filters: Partial<BenefitBrandsFilters>;
}

interface AddBrand {
  benefitId: string;
  brandId: string;
}

interface RemoveBrand {
  benefitId: string;
  brandId: string;
}

interface BulkAddBrands {
  benefitId: string;
  filters: Partial<BenefitBrandsFilters>;
}

interface BulkRemoveBrands {
  benefitId: string;
  filters: Partial<BenefitBrandsFilters>;
}

interface GetConfig {
  benefitId: string;
}

interface UpdateConfig {
  benefitId: string;
  config: Partial<BenefitBrandsFilters>;
}

interface RemoveConfig {
  benefitId: string;
}

export const loaders = {
  getBrands: ({ benefitId, filters }: GetBrands) => {
    return api<TableDataResponse<IBaseBrand & IRecognitionBrandWithSelection>>({
      url: Endpoints.clientAdmin.benefits.brands.root.replace(
        ':benefitId',
        benefitId,
      ),
      params: {
        ...getFilters(filters),
      },
    });
  },
  addBrand: ({ benefitId, brandId }: AddBrand) => {
    return api({
      url: Endpoints.clientAdmin.benefits.brands.byId
        .replace(':benefitId', benefitId)
        .replace(':brandId', brandId),
      method: 'post',
    });
  },
  removeBrand: ({ benefitId, brandId }: RemoveBrand) => {
    return api({
      url: Endpoints.clientAdmin.benefits.brands.byId
        .replace(':benefitId', benefitId)
        .replace(':brandId', brandId),
      method: 'delete',
    });
  },
  bulkAddBrands: ({ benefitId, filters }: BulkAddBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.benefits.brands.selection.bulk.replace(
        ':benefitId',
        benefitId,
      ),
      params: availableFilters,
      method: 'post',
    });
  },
  bulkRemoveBrands: ({ benefitId, filters }: BulkRemoveBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.benefits.brands.selection.bulk.replace(
        ':benefitId',
        benefitId,
      ),
      params: availableFilters,
      method: 'delete',
    });
  },
  getConfig: ({ benefitId }: GetConfig) => {
    return api<IRecognitionBrandsConfig>({
      url: Endpoints.clientAdmin.benefits.brands.config.root.replace(
        ':benefitId',
        benefitId,
      ),
    });
  },
  updateConfig: ({ benefitId, config }: UpdateConfig) => {
    return api({
      url: Endpoints.clientAdmin.benefits.brands.config.root.replace(
        ':benefitId',
        benefitId,
      ),
      method: 'put',
      params: {
        filter: getFilters(config).filter,
      },
    });
  },
  removeConfig: ({ benefitId }: RemoveConfig) => {
    return api({
      url: Endpoints.clientAdmin.benefits.brands.config.root.replace(
        ':benefitId',
        benefitId,
      ),
      method: 'delete',
    });
  },
};
