import { FC } from 'react';
import { Chip as MuiChip } from '@mui/material';
import { Sx } from 'types/Common';
import { SystemColors } from 'melp-design/style';

type Variant = 'info' | 'neutral' | 'warning' | 'error';

interface Props {
  label: string | number;
  variant?: Variant;
}

const STYLES_BY_VARIANT: Record<Variant, Sx> = {
  info: {
    color: SystemColors.white,
    backgroundColor: SystemColors.primary.base,
  },
  neutral: {
    color: SystemColors.black,
    backgroundColor: SystemColors.grey[96],
  },
  warning: {
    color: SystemColors.white,
    backgroundColor: SystemColors.warning.base,
  },
  error: {
    color: SystemColors.white,
    backgroundColor: SystemColors.danger.base,
  },
};

export const Chip: FC<Props> = ({ label, variant = 'info' }) => {
  return (
    <MuiChip
      label={label}
      sx={{
        borderRadius: 1,
        height: 20,
        minWidth: 20,
        ...STYLES_BY_VARIANT[variant],

        '> .MuiChip-label': {
          fontSize: 13,
          fontWeight: 500,
          lineHeight: 1,
          p: 1,
        },
      }}
    />
  );
};
