import { FC, useMemo } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import {
  Button,
  InfiniteList,
  ListCard,
  ListPageHeader,
  Tabs,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import {
  brandProviders,
  brandsFiltersSchema,
  useAddBrand,
  useBulkAddBrands,
  useBulkRemoveBrands,
  useInfiniteBrands,
  useRemoveBrand,
} from 'store/brands';
import { useSearchParams } from 'utils/navigation';
import {
  BrandsFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import { shopItemCategories } from 'types/MarketplaceItems';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { usePredefinedToasts } from 'utils/Toast';
import {
  BrandDetailsModal,
  BrandsDisabledFeatureAlert,
} from 'containers/brands';
import { useModalContext } from 'store/modal';
import { richTextAsPlainText } from 'utils/richTextAsPlainText';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import TextFilter from 'components/filters/TextFilter';

export const BrandsList: FC = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { openModal, closeModal } = useModalContext();
  const { defaultLanguage } = useCompanyLanguages();

  const { searchParams: filters, navigate } =
    useSearchParams(brandsFiltersSchema);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isPreviousData,
  } = useInfiniteBrands(filters);

  const {
    mutate: addBrand,
    isLoading: isAdding,
    variables: addBrandVariables,
  } = useAddBrand();
  const {
    mutate: removeBrand,
    isLoading: isRemoving,
    variables: removeBrandVariables,
  } = useRemoveBrand();
  const { mutate: bulkAddBrands, isLoading: isBulkAdding } = useBulkAddBrands();
  const { mutate: bulkRemoveBrands, isLoading: isBulkRemoving } =
    useBulkRemoveBrands();

  const brands = useMemo(() => {
    return {
      items: (data?.pages.map(({ items }) => items) ?? []).flat(),
      total: data?.pages[0]?.total ?? 0,
    };
  }, [data]);

  const { selection, ...baseFilters } = filters;

  return (
    <>
      <ListPageHeader
        title={t('menu.marketplace')}
        top={<BrandsDisabledFeatureAlert />}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Tabs
              value={selection}
              onChange={(v) => navigate({ params: { selection: v } })}
              items={[
                {
                  value: 'all',
                  label: t('marketplace.all'),
                },
                {
                  value: 'selected',
                  label: t('marketplace.selected'),
                },
              ]}
            />

            <Stack direction="row" alignItems="center" gap={1}>
              <Filters
                value={baseFilters}
                initialValues={brandsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('brands.title')}
                      value={value.brands}
                      initialValue={initialValues.brands}
                      onChange={(v) => setFilter('brands', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <BrandsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('brands.primary_category.label')}
                      value={value.primaryCategories}
                      initialValue={initialValues.primaryCategories}
                      onChange={(v) => setFilter('primaryCategories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="primaryCategories"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('providers.title')}
                      value={value.providers}
                      initialValue={initialValues.providers}
                      onChange={(v) => setFilter('providers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="providers"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={brandProviders.map((provider) => ({
                            key: provider,
                            label: provider,
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplace.filtersPriceRange')}
                      value={value.price}
                      initialValue={initialValues.price}
                      onChange={(v) => setFilter('price', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <NumberRangeFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <TextFilter
                      value={value.search}
                      onApplyFilter={(v) => setFilter('search', v)}
                      placeholder={t('common.search')}
                    />
                  </>
                )}
              </Filters>

              {brands.total ? (
                <>
                  <Divider orientation="vertical" variant="middle" flexItem />

                  {selection === 'all' ? (
                    <Button
                      label={t('marketplace.selectFilteredItems', {
                        count: brands.total,
                      })}
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        bulkAddBrands(
                          { filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isBulkAdding ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : selection === 'selected' ? (
                    <Button
                      label={t('marketplace.removeFilteredItems', {
                        count: brands.total,
                      })}
                      variant="danger-outline"
                      size="sm"
                      onClick={() =>
                        bulkRemoveBrands(
                          { filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isBulkRemoving ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Stack>
          </Stack>
        }
      />

      <Box sx={{ py: 2, px: { xs: 2, lg: 0 } }}>
        <InfiniteList
          data={brands.items}
          renderItem={(brand) => {
            const translation = resolveTranslation(
              brand.content,
              i18n.language,
              defaultLanguage,
            );

            return (
              <ListCard
                title={brand.name}
                description={richTextAsPlainText(
                  translation?.description ?? '',
                )}
                image={brand.logo?.url}
                // TODO
                // label={}
                onClick={() => {
                  openModal({
                    component: BrandDetailsModal,
                    props: {
                      brandId: brand.id,
                      actions: [
                        brand.isSelected
                          ? {
                              label: t('marketplace.removeItem'),
                              variant: 'danger-fill',
                              onClick: () => {
                                removeBrand(
                                  { brandId: brand.id },
                                  {
                                    onSuccess: () => {
                                      closeModal();
                                      predefinedToasts.success.updated();
                                    },
                                  },
                                );
                              },
                              disabled: isRemoving,
                            }
                          : {
                              label: t('marketplace.selectItem'),
                              variant: 'primary',
                              onClick: () => {
                                addBrand(
                                  { brandId: brand.id },
                                  {
                                    onSuccess: () => {
                                      closeModal();
                                      predefinedToasts.success.updated();
                                    },
                                  },
                                );
                              },
                              disabled: isAdding,
                            },
                      ],
                    },
                    size: 'lg',
                  });
                }}
                action={
                  brand.isSelected
                    ? {
                        label: t('marketplace.removeItem'),
                        variant: 'danger-outline',
                        onClick: () => {
                          removeBrand(
                            { brandId: brand.id },
                            {
                              onSuccess: () => {
                                predefinedToasts.success.updated();
                              },
                            },
                          );
                        },
                        disabled:
                          isRemoving &&
                          removeBrandVariables?.brandId === brand.id,
                      }
                    : {
                        label: t('marketplace.selectItem'),
                        variant: 'secondary',
                        onClick: () => {
                          addBrand(
                            { brandId: brand.id },
                            {
                              onSuccess: () => {
                                predefinedToasts.success.updated();
                              },
                            },
                          );
                        },
                        disabled:
                          isAdding && addBrandVariables?.brandId === brand.id,
                      }
                }
              />
            );
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};
