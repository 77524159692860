import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'store/news';
import { recognitionsKeys } from 'store/recognitions';
import { loaders } from './reactions.loaders';

export const useCreateReaction = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createReaction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(newsKeys.all),
        queryClient.invalidateQueries(recognitionsKeys.all),
      ]);
    },
  });
};

export const useDeleteReaction = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteReaction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(newsKeys.all),
        queryClient.invalidateQueries(recognitionsKeys.all),
      ]);
    },
  });
};
