import { ComponentPropsWithoutRef, FC } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import {
  FOOTER_HEIGHT,
  FOOTER_ID,
  Menu,
  Typography,
} from 'melp-design/components';
import { Close } from 'melp-design/icons';
import { SystemColors } from 'melp-design/style';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedItems?: string[];
  actions?: Array<
    NonNullable<ComponentPropsWithoutRef<typeof Menu>['items'][number]>
  >;
  onClear?: () => void;
  isLoading?: boolean;
  offsetLeft?: number;
}

const OFFSET = 10;

export const Floater: FC<Props> = ({
  actions = [],
  selectedItems = [],
  onClear,
  isLoading,
  offsetLeft = 0,
}) => {
  const { t } = useTranslation();

  const isFooterVisible = !!document.getElementById(FOOTER_ID);

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1,
        bottom: isFooterVisible ? FOOTER_HEIGHT + OFFSET : OFFSET,
        left: offsetLeft + OFFSET,
      }}
    >
      <Stack
        sx={{
          p: 2,
          backgroundColor: SystemColors.primary.base,
          borderRadius: 1,
          width: 250,
        }}
        gap={1}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{ color: SystemColors.white }}
        >
          <Typography variant="h3" color="textWhite">
            {t('common.selectedRowsCount', { count: selectedItems.length })}
          </Typography>
          {onClear ? (
            <IconButton
              size="small"
              color="inherit"
              onClick={() => onClear()}
              aria-label={t('common.close')}
            >
              <Close />
            </IconButton>
          ) : null}
        </Stack>

        <Menu
          label={t('common.actions')}
          items={actions.map((action) => ({
            ...action,
            disabled: action.disabled || isLoading,
          }))}
        />
      </Stack>
    </Box>
  );
};
