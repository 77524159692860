import { Stack } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APP, ROUTES } from 'config';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  DateTimeInput,
  Footer,
  Loader,
  NothingFoundAlert,
  Panel,
  SelectInput,
  SwitchInput,
  TextField,
} from 'melp-design/components';
import { currencies, Currency } from 'types/general';
import {
  DepositManagementMethod,
  depositManagementMethods,
  useAdminClient,
  useAdminUpdateClient,
} from 'store/admin-clients';
import { useRouteParams } from 'utils/useIdParam';
import { formatCountry } from 'utils/format';
import { usePredefinedToasts } from 'utils/Toast';
import { CompanyStatus, companyStatuses } from 'store/companies';

interface Values {
  status: CompanyStatus;
  creationDate: string;
  country: string;
  name: string;
  code: string;
  defaultCurrency: Currency;
  internalComment: string;
  depositManagementMethod: DepositManagementMethod;
  useExternalId: boolean;
}

export const AdminClientSettings = () => {
  const { t, i18n } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.settings,
  );
  const predefinedToasts = usePredefinedToasts();

  const { data: client, isLoading } = useAdminClient(clientId);

  const { mutate: updateClient, isLoading: isUpdating } =
    useAdminUpdateClient();

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      status: 'active',
      creationDate: '',
      country: '',
      name: '',
      code: '',
      defaultCurrency: 'EUR',
      internalComment: '',
      depositManagementMethod: 'company',
      useExternalId: false,
    },
  });

  useEffect(() => {
    if (client) {
      reset({
        name: client.name,
        creationDate: moment(client.creationDate).format('l LT'),
        country: client.country,
        code: client.code,
        defaultCurrency: client.defaultCurrency,
        internalComment: client.internalComment,
        status: client.status,
        depositManagementMethod: client.settings.depositManagementMethod,
        useExternalId: client.settings.useExternalId,
      });
    }
  }, [client, reset]);

  if (isLoading) return <Loader />;
  if (!client) return <NothingFoundAlert />;

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateClient(
      {
        clientId,
        data: {
          name: values.name,
          code: values.code,
          defaultCurrency: values.defaultCurrency,
          country: values.country,
          internalComment: values.internalComment,
          depositManagementMethod: values.depositManagementMethod,
          useExternalId: values.useExternalId,
          deactivationDate:
            values.status === 'inactive' ? moment().toISOString() : null,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  return (
    <>
      <Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Panel title={t('sidebar.administratorsInformation')}>
              <Stack spacing={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SelectInput
                        label={t('common.status')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={companyStatuses.map((status) => ({
                          label: t(`status.${status}`),
                          value: status,
                        }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="creationDate"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <DateTimeInput
                      label={t('clients.creationDate')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      disabled
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  name="country"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <AutocompleteInput
                        label={t('benefits.country')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={APP.countries.alpha3.map((alpha3) => ({
                          value: alpha3,
                          label: formatCountry(i18n.language, alpha3),
                        }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="name"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <TextField
                        label={t('companies.company_name')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="code"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <TextField
                        label={t('companies.company_code')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="defaultCurrency"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SelectInput
                        label={t('clients.default_currency')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={currencies.map((currency) => ({
                          label: currency,
                          value: currency,
                        }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="internalComment"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <TextField
                        label={t('common.internal_comment')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        rows={5}
                        ref={ref}
                      />
                    );
                  }}
                />
              </Stack>
            </Panel>

            <Panel title={t('common.settings')}>
              <Stack gap={2}>
                <Controller
                  name="depositManagementMethod"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <SelectInput
                        label={t('deposits.depositManagementMethod.label')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={depositManagementMethods.map((method) => ({
                          label: t(`deposits.method.${method}`),
                          value: method,
                        }))}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />

                <Controller
                  name="useExternalId"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <SwitchInput
                      label={t('clients.useExternalId.label')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </Stack>
            </Panel>
          </Stack>

          <Footer visible={isDirty}>
            <Stack direction="row" justifyContent="end" gap={1}>
              <Button
                label={t('common.cancel')}
                variant="neutral-outline"
                onClick={() => {
                  reset(undefined, { keepDirty: false });
                }}
              />
              <Button
                label={t('common.confirm_changes')}
                type="submit"
                disabled={isUpdating}
              />
            </Stack>
          </Footer>
        </form>
      </Stack>

      <DataLossPrompt when={isDirty} />
    </>
  );
};
