import { Stack } from '@mui/material';
import { APP } from 'config';
import {
  Modal,
  SelectInput,
  SortableList,
  Typography,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from 'state/Administrators';
import {
  sourcesOfRecognitionPoints,
  useUpdateCompanySettings,
} from 'store/companies';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';

interface Props extends ModalProps {}

export const SourceOfPointsModal: FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { me, canEditCompanies } = useMe();
  const predefinedToasts = usePredefinedToasts();

  const { mutate: updateCompanySettings, isLoading } =
    useUpdateCompanySettings();

  return (
    <Modal.Content
      title={t('companies.settings.sourceOfRecognitionPoints.label')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal(),
          label: t('buttons.ok'),
        },
      ]}
    >
      {me?.parentCompany.companies.length ? (
        <SortableList
          key={JSON.stringify(
            me.parentCompany.companies.map((company) => company.id),
          )}
          disabled
          items={me.parentCompany.companies.map((company) => ({
            id: company.id,
            content: (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                py={1}
                px={2}
                sx={{ backgroundColor: SystemColors.white }}
                borderRadius={1}
                boxShadow={`0 0 0 1px ${SystemColors.grey[90]}`}
                key={company.id}
              >
                <Stack flex={1} gap={0.25}>
                  <Typography variant="h3">{company.name}</Typography>
                  <Typography variant="p1" color="textSecondary">
                    {t('marketplace.employeeID', { id: company.id })}
                  </Typography>
                </Stack>

                <Stack flex={0.5}>
                  <SelectInput
                    label={t(
                      'companies.settings.sourceOfRecognitionPoints.label',
                    )}
                    name="sourceOfRecognitionPoints"
                    value={company.settings.sourceOfRecognitionPoints}
                    onChange={(source) => {
                      updateCompanySettings(
                        {
                          companyId: company.id,
                          data: {
                            externalRecognitionProvider:
                              source === 'externalProvider',
                          },
                        },
                        {
                          onSuccess: () => {
                            predefinedToasts.success.updated();
                          },
                        },
                      );
                    }}
                    options={sourcesOfRecognitionPoints.map((source) => ({
                      value: source,
                      label:
                        source === 'melp'
                          ? APP.name
                          : t(
                              `companies.settings.sourceOfRecognitionPoints.${source}`,
                            ),
                    }))}
                    disabled={!canEditCompanies || isLoading}
                  />
                </Stack>
              </Stack>
            ),
          }))}
        />
      ) : null}
    </Modal.Content>
  );
};
