import { APP } from 'config';
import i18n from 'i18n';
import { Translations } from 'types/Common';

export const resolveTranslationByLanguage = <
  T extends Translations<Record<string, any>>,
>(
  translations: T,
  language: string,
): T[number] | null => {
  return (
    translations.find((trans) => trans.language === language.toUpperCase()) ||
    null
  );
};

export const resolveTranslation = <T extends Translations<Record<string, any>>>(
  translations: T,
  userLanguage: string = i18n.language,
  defaultLanguage: string = APP.locales.default,
): T[number] | null => {
  return (
    resolveTranslationByLanguage(translations, userLanguage) ||
    resolveTranslationByLanguage(translations, defaultLanguage) ||
    translations[0] ||
    null
  );
};
