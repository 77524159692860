import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-brands.loaders';
import { converters } from './recognition-brands.converters';
import { RecognitionBrandsFilters } from './recognition-brands.types';

export const recognitionBrandsKeys = queryKeyFactory('recognition-brands');

export const useRecognitionBrands = (
  filters: Partial<RecognitionBrandsFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionBrandsKeys.list(filters),
    queryFn: () => loaders.getBrands({ filters }),
    select: converters.getBrands,
  });
};

export const useInfiniteRecognitionBrands = (
  filters: Partial<RecognitionBrandsFilters> = {},
) => {
  return useInfiniteQuery(
    recognitionBrandsKeys.list('infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getBrands({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getBrands(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );
};

export const useRecognitionBrandsConfig = () => {
  return useQuery({
    queryKey: recognitionBrandsKeys.detail('config'),
    queryFn: () => loaders.getConfig(),
    select: converters.getConfig,
  });
};
