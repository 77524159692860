import { FC } from 'react';
import {
  Button,
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Tile,
} from 'melp-design/components';
import moment from 'moment';
import { Divider, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { useSearchParams } from 'utils/navigation';
import {
  adminDepositHistoryFiltersSchema,
  clientCompanyDepositHistoryTypes,
  useAdminDepositHistory,
} from 'store/admin-client-deposits';
import { formatCurrency } from 'utils/format';
import { ROUTES } from 'config';
import {
  AdminAdminsFilter,
  DateFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import { EditDepositModal } from 'containers/admin';
import { useModalContext } from 'store/modal';

export const AdminClientDepositHistoryListPerCompany: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.deposits.list,
  );
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    adminDepositHistoryFiltersSchema,
  );

  const { data: deposits, isLoading } = useAdminDepositHistory(
    clientId,
    filters,
  );

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack direction="row" alignItems="center" gap="15px">
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
              </>
            ) : deposits ? (
              <>
                <Tile
                  title={t('clients.deposits.balance')}
                  items={[
                    formatCurrency(
                      i18n.language,
                      deposits.meta.totalRemainingDeposit,
                    ),
                  ]}
                />
                <Tile
                  title={t('common.credit.label')}
                  items={[
                    formatCurrency(i18n.language, deposits.meta.totalCredit),
                  ]}
                />
              </>
            ) : null}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={deposits?.items.length} />

            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={adminDepositHistoryFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('integrations.type')}
                      value={value.type}
                      initialValue={initialValues.type}
                      onChange={(v) => setFilter('type', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          searchable={false}
                          options={clientCompanyDepositHistoryTypes.map(
                            (type) => ({
                              key: type,
                              label: t(`deposits.types.${type}`),
                            }),
                          )}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.date')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.done_by')}
                      value={value.owner}
                      initialValue={initialValues.owner}
                      onChange={(v) => setFilter('owner', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminAdminsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={deposits?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'date',
            header: t('common.date'),
            cell: ({ row: { original: deposit } }) => {
              return moment(deposit.date).format('l');
            },
          },
          {
            id: 'type',
            header: t('integrations.type'),
            cell: ({ row: { original: deposit } }) => {
              return t(`deposits.types.${deposit.type}`);
            },
          },
          {
            id: 'company',
            header: t('employees.company'),
            cell: ({ row: { original: deposit } }) => {
              return deposit.company?.name ?? null;
            },
          },
          {
            id: 'amount',
            header: t('benefitExpenses.expenseAmount'),
            cell: ({ row: { original: deposit } }) => {
              return formatCurrency(i18n.language, deposit.amount);
            },
            meta: { align: 'right' },
          },
          {
            id: 'doneBy',
            header: t('common.done_by'),
            cell: ({ row: { original: deposit } }) => {
              return (
                <Link
                  label={deposit.admin.name}
                  to={ROUTES.admin.admins.details.replace(
                    ':id',
                    deposit.admin.id,
                  )}
                />
              );
            },
          },
          {
            id: 'invoice',
            header: t('common.invoice_number.label'),
            cell: ({ row: { original: deposit } }) => {
              return deposit.invoice;
            },
          },
          {
            id: 'comment',
            header: t('clients.comment'),
            cell: ({ row: { original: deposit } }) => {
              return deposit.comment;
            },
          },
          {
            id: 'actions',
            cell: ({ row: { original: deposit } }) => (
              <Button
                label={t('common.edit')}
                variant="neutral-outline"
                size="sm"
                onClick={() => {
                  openModal({
                    component: EditDepositModal,
                    props: {
                      depositId: deposit.id,
                      clientId,
                      initialValues: {
                        date: deposit.date,
                        amount: deposit.amount,
                        comment: deposit.comment,
                        invoice: deposit.invoice,
                      },
                    },
                  });
                }}
              />
            ),
          },
        ]}
      />
    </TableWrapper>
  );
};
