import * as Sentry from '@sentry/react';
import axios from 'axios';
import { HttpBackendOptions } from 'i18next-http-backend';
import { defaultsDeep } from 'lodash';
import { AppLanguage } from 'types/general';
import localTranslations from '../locales/dev.json';

const parseLanguageResponse = (data: object) => {
  const entries = Object.entries(data);
  const parsedEntries = entries.map(([key, entryValue]) => {
    const innerEntries = Object.entries(entryValue);
    const innerEntriesMapped = innerEntries.map(([key, value]) => {
      return [
        key,
        !!(value as any)?.translation ? (value as any)?.translation : undefined,
      ];
    });
    const innerEntryObject = Object.fromEntries(innerEntriesMapped);
    return [key, innerEntryObject];
  });
  return Object.fromEntries(parsedEntries);
};

const toLocale = (language: AppLanguage) => {
  const localeMap: Partial<Record<AppLanguage, string>> = {
    bg: 'bg_BG',
    cs: 'cs_CZ',
    et: 'et_EE',
    de: 'de_DE',
  };
  const locale = localeMap[language];
  return locale ?? language;
};

const loadLanguageResource = async (locale: string) => {
  const url = `https://translations.services.melp.com/locale/${locale}.json`;
  const response = await axios.get(url);
  const translationsResponse = parseLanguageResponse(response.data);
  if (process.env.NODE_ENV === 'development') {
    return defaultsDeep(localTranslations, translationsResponse);
  }
  return translationsResponse;
};

export const languageRequest: HttpBackendOptions['request'] = async (
  _options,
  url,
  _payload,
  callback,
) => {
  try {
    const [lng] = url.split('|');
    if (lng === 'none') {
      Sentry.captureMessage("Requesting language 'none'");
      callback(null, {
        data: '{}',
        status: 200,
      });
      return;
    }
    const response = await loadLanguageResource(toLocale(lng as AppLanguage));
    callback(null, {
      data: { ...response },
      status: 200,
    });
  } catch (e) {
    Sentry.captureException(e);
    callback(e, {
      status: 500,
      // As per documentation: "'res' should be an object <...> or null in case
      // of an error". Incorrect field type defined in official types.
      data: null as any,
    });
  }
};
