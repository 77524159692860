import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  minHeight?: number;
  size?: number;
}

export const Loader: FC<Props> = ({ minHeight = 200, size }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
      minHeight={size ? undefined : minHeight}
    >
      <CircularProgress color="primary" disableShrink size={size} />
    </Box>
  );
};
