import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { Typography } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  maxLength: number;
  textProps?: Pick<
    ComponentPropsWithoutRef<typeof Typography>,
    'variant' | 'color'
  >;
}

export const TruncatedText: FC<Props> = ({ text, maxLength, textProps }) => {
  const { t } = useTranslation();

  const [isTruncated, setIsTruncated] = useState(true);

  const shouldTruncate = text.length > maxLength;

  return (
    <Box>
      <Typography component="span" {...textProps}>
        {shouldTruncate && isTruncated
          ? `${text.substring(0, maxLength)}...`
          : text}
      </Typography>

      {shouldTruncate && isTruncated ? (
        <ButtonBase
          type="button"
          onClick={() => {
            setIsTruncated((prev) => !prev);
          }}
          sx={{
            color: SystemColors.grey[55],
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: 'baseline',
            ml: 0.5,
          }}
        >
          {t('marketplace.showMorePrices')}
        </ButtonBase>
      ) : null}
    </Box>
  );
};
