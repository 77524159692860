import { Tab, Tabs as MuiTabs } from '@mui/material';
import { SystemColors } from 'melp-design/style';
import { Badge } from 'melp-design/components';

interface Props<T extends string> {
  value: T;
  items: Array<{ value: T; label: string; badge?: number }>;
  onChange?: (value: T) => void;
}

export const Tabs = <T extends string>({
  value,
  onChange,
  items,
}: Props<T>) => {
  return (
    <MuiTabs
      value={value}
      onChange={(_, v) => onChange?.(v)}
      TabIndicatorProps={{
        sx: { display: 'none' },
      }}
      sx={{
        minHeight: 'auto',
        '& .MuiTabs-flexContainer': {
          gap: 0.5,
        },
        '& .MuiTabs-scroller': {
          overflow: 'visible !important',
        },
        overflow: 'visible',
      }}
    >
      {items.map((item) => (
        <Tab
          value={item.value}
          label={
            <Badge
              badgeContent={item.badge}
              color="error"
              sx={{ '.MuiBadge-badge': { left: '97%' } }}
              max={9}
            >
              {item.label}
            </Badge>
          }
          disableRipple
          sx={{
            px: 1.75,
            py: 1,
            borderRadius: 1,
            '&:hover': {
              color: SystemColors.grey[55],
              backgroundColor: SystemColors.grey[98],
            },
            '&.Mui-selected': {
              color: SystemColors.primary.base,
              backgroundColor: SystemColors.primary.tint,
              fontWeight: 400,
            },
            '&.MuiButtonBase-root': {
              overflow: 'visible',
            },
          }}
          key={item.value}
        />
      ))}
    </MuiTabs>
  );
};
