import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './recognition-brands.loaders';
import { recognitionBrandsKeys } from './recognition-brands.queries';

export const useAddRecognitionBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.addBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};

export const useRemoveRecognitionBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};

export const useBulkAddRecognitionBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkAddBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};

export const useBulkRemoveRecognitionBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkRemoveBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};

export const useUpdateRecognitionBrandsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};

export const useRemoveRecognitionBrandsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionBrandsKeys.all);
    },
  });
};
