import { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import { Menu } from 'melp-design/components';

interface Props
  extends Omit<
    ComponentPropsWithoutRef<typeof Menu>,
    'label' | 'icon' | 'expandIndicator'
  > {
  type?: 'comfort' | 'compact';
}

export const ActionsMenu: FC<Props> = ({ type = 'comfort', ...menuProps }) => {
  const { t } = useTranslation();

  return (
    <Menu
      {...(type === 'comfort'
        ? {
            label: t('common.actions'),
          }
        : type === 'compact'
        ? {
            icon: MoreVert,
            expandIndicator: false,
          }
        : {})}
      {...menuProps}
    />
  );
};
