import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IReaction } from 'types/income';
import {
  ReactionEntity,
  ReactionsFilters,
  ReactionsSummary,
  ReactionsVariant,
} from './reactions.types';

const getFilters = (filters: Partial<ReactionsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    emoji: filters.reaction,
  },
});

interface GetReactions {
  hostId: string;
  filters: Partial<ReactionsFilters>;
}

interface CreateReaction {
  entity: ReactionEntity;
  hostId: string;
  emoji: ReactionsVariant;
}

interface DeleteReaction {
  hostId: string;
}

export const loaders = {
  getReactions: ({ hostId, filters }: GetReactions) => {
    return api<TableDataResponse<IReaction> & { bar: ReactionsSummary }>({
      url: Endpoints.clientAdmin.reactions.byHost.replace(':hostId', hostId),
      params: {
        ...getFilters(filters),
      },
    });
  },
  createReaction: ({ entity, hostId, emoji }: CreateReaction) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.reactions.root,
      data: {
        entity,
        hostId,
        emoji,
      },
      method: 'post',
    });
  },
  deleteReaction: ({ hostId }: DeleteReaction) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.reactions.byHost.replace(':hostId', hostId),
      method: 'delete',
    });
  },
};
