import { FC } from 'react';
import { Stack } from '@mui/material';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsBrandsList } from 'containers/recognitions';
import { BrandsDisabledFeatureAlert } from 'containers/brands';

const RecognitionsBrandsPage: FC = () => {
  return (
    <RecognitionsLayout>
      <Stack gap={2}>
        <BrandsDisabledFeatureAlert />
        <RecognitionsBrandsList />
      </Stack>
    </RecognitionsLayout>
  );
};

export default RecognitionsBrandsPage;
