import { FC } from 'react';
import { Stack } from '@mui/material';
import { orderBy } from 'lodash';
import { LinkAsButton, Typography } from 'melp-design/components';
import { EMOJI_BY_REACTION, ReactionsSummary } from 'store/reactions';
import { sum } from 'utils/general';

interface Props {
  reactions: ReactionsSummary;
  onClick?: () => void;
  maxReactions?: number;
}

export const ReactionsPreview: FC<Props> = ({
  reactions,
  onClick,
  maxReactions = 5,
}) => {
  const parsedReactions = orderBy(reactions, ['count'], ['desc']).filter(
    (_, i) => i < maxReactions,
  );

  const preview = (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ whiteSpace: 'nowrap' }}>
        {parsedReactions.map((reaction) => EMOJI_BY_REACTION[reaction.emoji])}
      </Typography>
      <Typography color="textSecondary">
        {sum(...reactions.map((reaction) => reaction.count))}
      </Typography>
    </Stack>
  );

  return !parsedReactions.length ? null : onClick ? (
    <LinkAsButton label={preview} onClick={onClick} />
  ) : (
    <>{preview}</>
  );
};
