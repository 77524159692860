import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import { SystemColors } from 'melp-design/style';
import { FC } from 'react';

export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'p1' | 'p2' | 'p3';

export type TypographyColor =
  | 'textPrimary'
  | 'textSecondary'
  | 'textWhite'
  | 'primary'
  | 'error'
  | 'warning'
  | 'success'
  | 'inherit';

export const COLOR_BY_TYPOGRAPHY_COLOR: Record<TypographyColor, string> = {
  textPrimary: SystemColors.black,
  textSecondary: SystemColors.grey[55],
  textWhite: SystemColors.white,
  primary: SystemColors.primary.base,
  error: SystemColors.danger.base,
  warning: SystemColors.warning.base,
  success: SystemColors.success.base,
  inherit: 'inherit',
};

const FONT_SIZE_BY_VARIANT: Record<TypographyVariant, number> = {
  h1: 30,
  h2: 18,
  h3: 14,
  h4: 12,
  p1: 14,
  p2: 12,
  p3: 10,
};

const FONT_WEIGHT_BY_VARIANT: Record<TypographyVariant, number> = {
  h1: 500,
  h2: 500,
  h3: 500,
  h4: 600,
  p1: 400,
  p2: 400,
  p3: 400,
};

const LINE_HEIGHT_BY_VARIANT: Record<TypographyVariant, number> = {
  h1: 1.1,
  h2: 1.4,
  h3: 1.4,
  h4: 1.4,
  p1: 1.4,
  p2: 1.4,
  p3: 1.4,
};

interface Props {
  variant?: TypographyVariant;
  color?: TypographyColor;
  component?: 'span' | 'label';
  maxLines?: number;
}

export const Typography: FC<Props & Omit<TypographyProps, keyof Props>> = ({
  variant = 'p1',
  color = 'textPrimary',
  maxLines,
  ...props
}) => {
  const muiVariantMap: Record<TypographyVariant, TypographyProps['variant']> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    p1: 'body1',
    p2: 'body2',
    p3: 'body1',
  };

  return (
    <MuiTypography
      variant={muiVariantMap[variant]}
      whiteSpace="pre-wrap"
      {...props}
      sx={{
        ...props.sx,
        fontFamily: 'Inter',
        color: COLOR_BY_TYPOGRAPHY_COLOR[color],
        fontSize: FONT_SIZE_BY_VARIANT[variant],
        fontWeight: FONT_WEIGHT_BY_VARIANT[variant],
        lineHeight: LINE_HEIGHT_BY_VARIANT[variant],
        ...(maxLines
          ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: maxLines,
            }
          : {}),
      }}
    />
  );
};
