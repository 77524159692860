import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IAdminBaseBrand, IAdminBrand } from 'types/income';
import { exportXlsxConfig, getSorting } from 'utils/general';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { Translations } from 'types/Common';
import { BrandVariant } from 'store/brands';
import { AdminBrandsFilters, AdminBrandStatus } from './admin-brands.types';

const getFilters = (filters: Partial<AdminBrandsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    status: filters.status,
    type: filters.variant,
    brandIds: filters.brands,
    provider: filters.providers,
    country: filters.countries,
    primaryCategory: filters.primaryCategories,
    language: filters.languages,
  },
});

interface GetBrands {
  filters: Partial<AdminBrandsFilters>;
}

interface GetBrand {
  brandId: string;
}

interface CreateBrand {
  name: string;
  slug: string;
  type: BrandVariant;
  primaryCategory: ShopItemCategory;
  secondaryCategory: ShopItemCategory | null;
  priorityIndex: number;
  searchTags: string;
  logoId: string;
}

interface UpdateBrand {
  brandId: string;
  data: Partial<CreateBrand> & Partial<{ status: AdminBrandStatus }>;
}

interface UpdateBrandContent {
  brandId: string;
  translations: Translations<{
    description: string;
    shortDescription: string;
  }>;
}

interface DeleteBrand {
  brandId: string;
}

interface ExportXls {
  filters: Partial<AdminBrandsFilters>;
}

export const loaders = {
  getBrands: ({ filters }: GetBrands) => {
    return api<TableDataResponse<IAdminBaseBrand>>({
      url: Endpoints.melpAdmin.brands.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getBrand: ({ brandId }: GetBrand) => {
    return api<IAdminBrand>({
      url: Endpoints.melpAdmin.brands.byId.root.replace(':brandId', brandId),
    });
  },
  createBrand: (values: CreateBrand) => {
    return api<{ brandId: string }>({
      url: Endpoints.melpAdmin.brands.root,
      data: {
        ...values,
        secondaryCategory: values.secondaryCategory || undefined,
      },
      method: 'post',
    });
  },
  updateBrand: ({ brandId, data }: UpdateBrand) => {
    return api({
      url: Endpoints.melpAdmin.brands.byId.root.replace(':brandId', brandId),
      data,
      method: 'patch',
    });
  },
  updateBrandContent: ({ brandId, translations }: UpdateBrandContent) => {
    return api({
      url: Endpoints.melpAdmin.brands.byId.content.replace(':brandId', brandId),
      data: {
        content: translations.map((trans) => ({
          language: trans.language,
          description: trans.description,
          shortDescription: trans.shortDescription,
        })),
      },
      method: 'put',
    });
  },
  deleteBrand: ({ brandId }: DeleteBrand) => {
    return api({
      url: Endpoints.melpAdmin.brands.byId.root.replace(':brandId', brandId),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, ...filtersWithoutPagination } = getFilters(filters);
    return api({
      url: Endpoints.melpAdmin.brands.export.xls,
      params: filtersWithoutPagination,
      ...exportXlsxConfig,
    });
  },
};
