import { EmbeddedReactions } from 'store/reactions';

export const commentStatuses = ['visible', 'hidden'] as const;
export type CommentStatus = (typeof commentStatuses)[number];

export const commentEntities = ['news', 'recognition', 'benefit'] as const;
export type CommentEntity = (typeof commentEntities)[number];

export interface Comment {
  id: string;
  status: CommentStatus;
  date: string;
  owner: {
    type: 'admin' | 'employee';
    id: string;
    fullName: string;
  } | null;
  content: string;
  subject: {
    id: string;
    entity: CommentEntity;
    title: string;
  };
  reactions: EmbeddedReactions;
}
