import { FC, ReactNode, useState } from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './atoms';

interface Props {
  items: Array<{ id: string; content: ReactNode }>;
  onPositionChange?: (source: number, destination: number) => void;
  disabled?: boolean;
}

export const SortableList: FC<Props> = ({
  items,
  onPositionChange,
  disabled,
}) => {
  const [order, setOrder] = useState<string[]>(items.map((item) => item.id));
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      setOrder((prev) => {
        const oldIndex = prev.indexOf(String(active.id));
        const newIndex = prev.indexOf(String(over.id));

        onPositionChange?.(oldIndex, newIndex);

        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={order}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        <ol style={{ listStyle: 'none', padding: 0 }}>
          {order.map((id) => {
            const item = items.find((item) => item.id === id);

            return (
              <SortableItem id={id} disabled={disabled} key={id}>
                {item?.content ?? null}
              </SortableItem>
            );
          })}
        </ol>
      </SortableContext>
    </DndContext>
  );
};
