import { z } from 'zod';
import {
  numberRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { shopItemCategories } from 'types/MarketplaceItems';
import {
  brandProviders,
  brandsSelections,
  brandVariants,
} from './brands.types';

export const brandsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    variant: z.array(z.enum(brandVariants)).catch([]),
    brands: z.array(z.string()).catch([]),
    providers: z.array(z.enum(brandProviders)).catch([]),
    countries: z.array(z.string()).catch([]),
    primaryCategories: z.array(z.enum(shopItemCategories)).catch([]),
    price: numberRangeSchema,
    selection: z.enum(brandsSelections).catch('all'),
  })
  .merge(sortFiltersSchema)
  .merge(paginationFiltersSchema);
