import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { CommentEntity, CommentStatus } from './comments.types';

interface CreateComment {
  entity: CommentEntity;
  subjectId: string;
  content: string;
}

interface UpdateComment {
  commentId: string;
  data: {
    content: string;
  };
}

interface UpdateCommentStatus {
  status: CommentStatus;
  ids: string[];
}

interface DeleteComments {
  ids: string[];
}

export const loaders = {
  createComment: ({ entity, subjectId, content }: CreateComment) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.comments.root,
      data: {
        entity,
        subjectId,
        content,
      },
      method: 'post',
    });
  },
  updateComment: ({ commentId, data }: UpdateComment) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.comments.byId.replace(':commentId', commentId),
      data: {
        ...data,
      },
      method: 'patch',
    });
  },
  updateCommentsStatus: ({ ids, status }: UpdateCommentStatus) => {
    return api({
      url: Endpoints.clientAdmin.comments.update.status,
      data: { ids, status },
      method: 'patch',
    });
  },
  deleteComments: ({ ids }: DeleteComments) => {
    return api({
      url: Endpoints.clientAdmin.comments.bulk,
      data: { ids },
      method: 'delete',
    });
  },
};
