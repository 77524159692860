import { MpControl } from '@mp-react/form';
import { z } from 'zod';
import { BenefitCategory } from 'store/benefits';
import { ContentLanguage } from 'types/general';
import {
  dateRangeSchema,
  singleDateSchema,
  numberRangeSchema,
} from 'utils/schemas';
import { AdminPermission } from 'store/admin-admins';
import { AdministratorRole } from 'store/administrators';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from '../constants/Administrators';
import { StatusAction } from './Status';

type LinkModule =
  | LocalPermissionModules
  | GlobalPermissionModules
  | Array<LocalPermissionModules | GlobalPermissionModules>;

interface BaseLink {
  title: string;
  badge?: number;
  roles: AdministratorRole[];
  module?: LinkModule;
  permissionLevel?: PermissionLevels;
  matchAllModules?: boolean;
}

interface OneLevelLink extends BaseLink {
  to: string;
}

interface TwoLevelsLink extends BaseLink {
  to?: string;
  links: OneLevelLink[];
}

export type LinkItem = OneLevelLink | TwoLevelsLink;

export interface PageItem {
  component: React.FC;
  path: string;
  pages?: PageItem[];
  public?: boolean;
  roles?: AdministratorRole[];
  module?:
    | LocalPermissionModules
    | GlobalPermissionModules
    | Array<LocalPermissionModules | GlobalPermissionModules>;
  adminModules?: AdminPermission[];
  permissionLevel?: PermissionLevels;
}

export interface Tab {
  component: React.FC;
  key: string | number;
  translationKey?: string;
  module?: LocalPermissionModules | GlobalPermissionModules;
}

export interface FormTab {
  key: string;
  label: string;
}

export interface Status {
  name: StatusAction;
  date?: string;
  tz?: number;
}

export interface Timezone {
  value: number;
  label: string;
}

export interface SelectOption {
  name: string;
  value: string;
}

export interface TooltipConfig {
  text: string;
  width?: number;
}

export interface TooltipControl extends MpControl {
  tooltip?: string | TooltipConfig;
  sufleris?: string;
  children?: TooltipControl[];
}

export interface CustomFormControl extends TooltipControl {
  disabled?: boolean;
  readonly?: boolean;
  children?: CustomFormControl[];
  disablePast?: boolean;
  disableFuture?: boolean;
  props?: Record<string, any>;
}

export interface UseTabs {
  currentTab: string;
  changeTab: (event: any, tab: string) => void;
}

export interface UseLoading {
  loading: boolean;
  stopLoading: () => void;
  startLoading: () => void;
}

export interface DatepickerItemType {
  shortName: string;
  key: string;
}

export interface CheckAssignBaseResponse {
  totalInvestmentAmount: number;
  investmentAmountChange: number;
}

enum InvestmentTypes {
  employee = 'employee',
  benefits = 'benefits',
  groups = 'groups',
}

export interface Investment {
  id: string;
  name: string;
  added: boolean;
  type: InvestmentTypes;
  benefitType?: BenefitCategory;
  employeeCount?: number;
  investment: number;
}

export enum BasicActivityStatus {
  active = 'active',
  inactive = 'inactive',
}

export type NumberRange = z.infer<typeof numberRangeSchema>;
export type DateRange = z.infer<typeof dateRangeSchema>;

export type SingleDate = z.infer<typeof singleDateSchema>;

/**
 * Type for extracting array element type.
 */
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export interface KeyLabelPair<K extends string = string> {
  key: K;
  label: string;
}

export interface PaginationParams {
  page?: number;
  pageSize?: number;
}

export interface PaginationFilters {
  page: number;
  pageSize: number;
}

export interface PaginatedDataResponse {
  count: number;
  pageSize: number;
  page: number;
}

export interface SortFilters<T extends string = string> {
  sort: {
    columnKey: T;
    order: 'asc' | 'desc';
  } | null;
}

export type Translations<T extends Record<string, any>> = Array<
  {
    language: Uppercase<ContentLanguage>;
  } & T
>;

export const binaries = ['yes', 'no'] as const;
export type Binary = (typeof binaries)[number];

// MUI performance issue with SxProps
// https://github.com/microsoft/TypeScript/issues/34801
export type Sx = any;
