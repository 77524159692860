import {
  IBaseBrand,
  IRecognitionBrandsConfig,
  IRecognitionBrandWithSelection,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import { BaseBrand, convertBaseBrand } from 'store/brands';
import { loaders } from './recognition-brands.loaders';
import {
  RecognitionBrandsConfig,
  RecognitionBrandWithSelection,
} from './recognition-brands.types';

const convertBrandWithSelection = (
  brand: IBaseBrand & IRecognitionBrandWithSelection,
): BaseBrand & RecognitionBrandWithSelection => {
  return {
    ...convertBaseBrand(brand),
    isWhitelisted: brand.isWhitelisted,
    isAutoAssigned: brand.isAutoassigned,
  };
};

const convertBrands = (
  brands: (IBaseBrand & IRecognitionBrandWithSelection)[],
): (BaseBrand & RecognitionBrandWithSelection)[] => {
  return brands.map(convertBrandWithSelection);
};

const convertConfig = (
  config: IRecognitionBrandsConfig,
): RecognitionBrandsConfig => {
  return {
    brands: config.brandIds ?? [],
    variant: config.type ?? [],
    primaryCategories: config.primaryCategory ?? [],
    providers: config.provider ?? [],
    countries: config.country ?? [],
    price:
      config.priceFrom || config.priceTo
        ? {
            from: config.priceFrom,
            to: config.priceTo,
          }
        : null,
  };
};

export const converters = {
  getBrands: ({ data }: Awaited<ReturnType<typeof loaders.getBrands>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBrands(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getConfig: ({ data }: Awaited<ReturnType<typeof loaders.getConfig>>) => {
    return convertConfig(data);
  },
};
