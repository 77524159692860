import { Divider, Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { useSearchParams } from 'utils/navigation';
import {
  adminBrandCardPriceTypes,
  adminBrandCardsFiltersSchema,
  adminBrandCardStatuses,
  COLOR_BY_STATUS,
  getFaceValueLabel,
  useAdminBrandCards,
  useExportToXls,
} from 'store/admin-brand-cards';
import { FilterButton, Filters, SelectFilter } from 'components/filters';
import { formatCountry, formatList, formatPercents } from 'utils/format';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';
import moment from 'moment';
import { brandProviders } from 'store/brands';

export const AdminBrandCards: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: brandId } = useRouteParams(ROUTES.admin.brands.settings);

  const { searchParams: filters, navigate } = useSearchParams(
    adminBrandCardsFiltersSchema,
  );

  const { data: cards, isLoading } = useAdminBrandCards({
    ...filters,
    brands: [brandId],
  });

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <TableWrapper>
      <TableToolbar sticky>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <ListTotal total={cards?.total} />

          <Stack direction="row" alignItems="center" gap="10px">
            <Filters
              value={filters}
              initialValues={adminBrandCardsFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
            >
              {({ value, initialValues, setFilter }) => (
                <>
                  <FilterButton
                    label={t('common.status')}
                    value={value.status}
                    initialValue={initialValues.status}
                    onChange={(v) => setFilter('status', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="status"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple={true}
                        searchable={false}
                        options={adminBrandCardStatuses.map((status) => ({
                          key: status,
                          label: t(`status.${status}`),
                        }))}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('benefits.country')}
                    value={value.countries}
                    initialValue={initialValues.countries}
                    onChange={(v) => setFilter('countries', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="countries"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple
                        options={APP.countries.alpha3.map((alpha3) => ({
                          key: alpha3,
                          label: formatCountry(i18n.language, alpha3),
                        }))}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('providers.title')}
                    value={value.providers}
                    initialValue={initialValues.providers}
                    onChange={(v) => setFilter('providers', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="providers"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple={true}
                        searchable={false}
                        options={brandProviders.map((provider) => ({
                          key: provider,
                          label: provider,
                        }))}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('marketplaceItems.priceType')}
                    value={value.priceType}
                    initialValue={initialValues.priceType}
                    onChange={(v) => setFilter('priceType', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="priceType"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple={true}
                        searchable={false}
                        options={adminBrandCardPriceTypes.map((type) => ({
                          key: type,
                          label: t(`brands.priceType.${type}`),
                        }))}
                      />
                    )}
                  </FilterButton>
                </>
              )}
            </Filters>

            <Divider orientation="vertical" variant="middle" flexItem />

            <DownloadExcelButton
              listLength={cards?.total ?? 0}
              exportToExcel={() =>
                exportToXls({ filters: { ...filters, brands: [brandId] } })
              }
              loading={isExportingToXls}
            />
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={cards?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'sequence',
            header: t('integrations.hiBobServiceUserId'),
            cell: ({ row: { original: card } }) => (
              <Link
                label={card.sequence}
                to={ROUTES.admin.cards.settings.replace(':id', card.id)}
              />
            ),
            meta: { sort: true },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: card } }) => (
              <Typography color={COLOR_BY_STATUS[card.status]}>
                {t(`status.${card.status}`)}
              </Typography>
            ),
            meta: { sort: true },
          },
          {
            id: 'countries',
            header: t('marketplaceItems.countries'),
            cell: ({ row: { original: card } }) =>
              formatList(
                i18n.language,
                card.countries.map(
                  (alpha3) => alpha3ToAlpha2(alpha3) ?? alpha3,
                ),
              ),
          },
          {
            id: 'faceValue',
            header: t('marketplace.ordersTablePrice'),
            cell: ({ row: { original: card } }) =>
              getFaceValueLabel({ ...card, language: i18n.language }),
            meta: { align: 'right' },
          },
          {
            id: 'faceValueCurrency',
            header: t('marketplaceItems.currency'),
            cell: ({ row: { original: card } }) => card.faceValueCurrency,
          },
          {
            id: 'provider_id',
            header: t('providers.title'),
            cell: ({ row: { original: card } }) => card.provider,
            meta: { sort: true },
          },
          {
            id: 'purchasePriceDiscountPercentage',
            header: t('marketplaceItems.discountPercentage'),
            cell: ({ row: { original: card } }) =>
              formatPercents(
                i18n.language,
                card.purchasePriceDiscountPercentage,
              ),
            meta: { align: 'right' },
          },
          {
            id: 'ordersCount',
            header: t('menu.orders'),
            cell: ({ row: { original: card } }) => card.ordersCount,
            meta: { align: 'right' },
          },
          {
            id: 'creation',
            header: t('marketplaceOrders.orderDate'),
            cell: ({ row: { original: code } }) =>
              moment(code.createdAt).format('l'),
            meta: { sort: true },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          cards && {
            count: cards.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
