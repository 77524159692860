import { IBaseNewItem, INewItem, INewsCategory } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertEmbeddedReactions } from 'store/reactions';
import { convertComments } from 'store/comments';
import { loaders } from './news.loaders';
import { NewItem, NewsCategory } from './news.types';

const convertCommonNew = (item: IBaseNewItem | INewItem) => {
  return {
    id: item.id,
    name: item.name,
    status: item.status,
    parentCompanyId: item.parentCompanyId,
    publishedAt: item.publishDate,
    inactiveAt: item.inactiveDate,
    sentCount: item.sentTo,
    commentsCount: item.commentCount,
    allowComments: item.allowComments,
    pinned: item.pinned,
    pinnedUntil: item.pinnedUntil || null,
    categories: item.categories ?? [],
    reactions: convertEmbeddedReactions({
      summary: item.reactions,
      selected: item.selectedEmoji,
    }),
  } satisfies Partial<NewItem>;
};

const convertNew = (item: INewItem): NewItem => {
  return {
    ...convertCommonNew(item),
    seenCount: 0,
    sendMethods: item.sendMethods,
    supportedLanguages: item.translations.map((trans) => trans.language),
    translations: item.translations,
  };
};

const convertBaseNew = (item: IBaseNewItem): NewItem => {
  return {
    ...convertCommonNew(item),
    seenCount: Number(item.seen) || 0,
    sendMethods: [],
    supportedLanguages: [],
    translations: [],
  };
};

const convertNews = (news: IBaseNewItem[]): NewItem[] => {
  return news.map(convertBaseNew);
};

const convertCategory = (category: INewsCategory): NewsCategory => {
  return {
    id: category.id,
    name: category.name,
    translations: category.translations,
    createdAt: category.createdAt,
    updatedAt: category.updatedAt,
  };
};

const convertCategories = (categories: INewsCategory[]): NewsCategory[] => {
  return categories.map(convertCategory);
};

export const converters = {
  getNews: ({ data }: Awaited<ReturnType<typeof loaders.getNews>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertNews(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getNew: ({ data }: Awaited<ReturnType<typeof loaders.getNew>>) => {
    return convertNew(data);
  },
  getCategories: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCategories>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCategories(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getCategory: ({ data }: Awaited<ReturnType<typeof loaders.getCategory>>) => {
    return convertCategory(data);
  },
  getComments: ({ data }: Awaited<ReturnType<typeof loaders.getComments>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertComments(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
