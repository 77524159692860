import { z } from 'zod';
import { Translations } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { UploadedFile } from 'store/files';
import { BrandProvider, BrandVariant } from 'store/brands';
import { adminBrandsFiltersSchema } from './admin-brands.schemas';

export const adminBrandStatuses = ['enabled', 'disabled', 'duplicate'] as const;
export type AdminBrandStatus = (typeof adminBrandStatuses)[number];

export interface AdminBaseBrand {
  id: string;
  sequence: number;
  name: string;
  slug: string;
  status: AdminBrandStatus;
  variant: BrandVariant;
  primaryCategory: ShopItemCategory | '';
  priorityIndex: number;
  providers: BrandProvider[];
  countries: string[];
  enabledCards: number;
  discountsCount: number;
  ordersCount: number;
}

export interface AdminBrand extends AdminBaseBrand {
  secondaryCategory: ShopItemCategory | '';
  searchTags: string;
  logo: UploadedFile | null;
  content: Translations<{ description: string; shortDescription: string }>;
  createdAt: string;
  updatedAt: string | null;
}

export type AdminBrandsFilters = z.infer<typeof adminBrandsFiltersSchema>;
