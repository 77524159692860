import { converters as tableConverters } from 'store/table';
import {
  IBaseCompany,
  IBaseParentCompany,
  ICompany,
  ICompanyContact,
  IListCompany,
  IParentCompany,
  IParentCompanyWithStats,
} from 'types/income';
import { uniqBy } from 'lodash';
import { loaders } from './companies.loaders';
import {
  BaseCompany,
  BaseParentCompany,
  ListCompany,
  Department,
  ParentCompany,
  ParentCompanyWithStats,
  Company,
  CompanyContact,
} from './companies.types';

export const convertBaseCompany = (company: IBaseCompany): BaseCompany => {
  return {
    id: company.id,
    name: company.name,
    status: company.status,
    parentCompanyId: company.parentCompanyId,
  };
};

export const convertBaseCompanies = (
  companies: IBaseCompany[],
): BaseCompany[] => {
  return companies.map(convertBaseCompany);
};

const convertListCompany = (company: IListCompany): ListCompany => {
  return {
    ...convertBaseCompany(company),
    status: company.status,
    parentCompanyId: company.parentCompanyId,
    companyGroups: company.companyGroups,
    employeeCount: Number(company.employeeCount),
    departmentCount: Number(company.departmentCount),
  };
};

const convertCompany = (company: ICompany): Company => {
  return {
    ...convertBaseCompany(company),
    code: company.code,
    country: company.country,
    currency: company.currency,
    logoId: company.logoId ?? '',
    additionalInfo: company.additionalInfo ?? '',
    supportedLanguages: company.supportedLanguages,
    creationDate: company.creationDate,
    activationDate: company.activationDate ?? null,
    deactivationDate: company.deactivationDate ?? null,
    companyGroups:
      company.companyGroups?.map((group) => ({
        id: group.id,
        name: group.name,
      })) ?? [],
    departments:
      company.departments?.map((department) => ({
        id: department.id,
        name: department.name,
        hidden: department.hidden,
      })) ?? [],
    settings: {
      sourceOfRecognitionPoints: company.settings.externalRecognitionProvider
        ? 'externalProvider'
        : 'melp',
      canGiveRecognition: company.settings.canGiveRecognition,
      requireShippingInfoForMerchandise:
        company.settings.requireShippingInfoForMerchandise,
    },
  };
};

const convertCompanies = (companies: IListCompany[]): ListCompany[] => {
  return companies.map(convertListCompany);
};

const convertCompanyContact = (contact: ICompanyContact): CompanyContact => {
  return {
    id: contact.id,
    phone: contact.phone,
    firstName: contact.firstName ?? '',
    lastName: contact.lastName ?? '',
    fullName: [contact.firstName, contact.lastName].filter(Boolean).join(' '),
    email: contact.email ?? '',
  };
};

const convertCompanyContacts = (
  contacts: ICompanyContact[],
): CompanyContact[] => {
  return contacts.map(convertCompanyContact);
};

export const convertBaseParentCompany = (
  client: IBaseParentCompany,
): BaseParentCompany => {
  return {
    id: client.id,
    name: client.name,
    country: client.country,
    internalComment: client.internalComment ?? '',
    status: client.status,
    activeEmployees: client.activeEmployees ?? 0,
    activeMobileUsers: client.activeMobileUsers ?? 0,
    inactiveEmployees: client.inactiveEmployees ?? 0,
  };
};

export const convertParentCompany = (client: IParentCompany): ParentCompany => {
  return {
    ...convertBaseParentCompany(client),
    code: client.code,
    defaultCurrency: client.defaultCurrency,
    creationDate: client.creationDate,
    deactivationDate: client.deactivationDate,
    supportedLanguages: client.supportedLanguages,
    features: client.features,
    companies: client.companies?.map(convertCompany) ?? [],
    settings: {
      defaultLanguage: client.settings.defaultLanguage,
      isPersonalCodeRequiredForLogin:
        client.settings.isPersonalCodeRequiredForLogin,
      isEmailVisibleInternally: client.settings.isEmailVisibleInternally,
      discountPlan: client.settings.discountPlan,
      depositManagementMethod: client.settings.depositManagementMethod,
      useExternalId: client.settings.useExternalId,
    },
  };
};

const convertParentCompaniesWithStats = (
  companies: IParentCompanyWithStats[],
): ParentCompanyWithStats[] => {
  return companies.map(convertBaseParentCompany);
};

const convertDepartments = (
  hierarchy: Awaited<ReturnType<typeof loaders.getDepartments>>['data'],
): Department[] => {
  const departments = hierarchy
    .map(({ companies }) =>
      companies.map((company) =>
        company.departments.map((department) => ({
          id: department.id,
          name: `${department.name} (${company.name})`,
          companyId: company.id,
        })),
      ),
    )
    .flat(2);

  return uniqBy(departments, 'id');
};

export const converters = {
  getCompanies: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCompanies>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCompanies(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getCompany: ({ data }: Awaited<ReturnType<typeof loaders.getCompany>>) => {
    return convertCompany(data);
  },
  getCompanyContacts: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCompanyContacts>>) => {
    return convertCompanyContacts(data);
  },
  getParentCompanies: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getParentCompanies>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertParentCompaniesWithStats(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepartments: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepartments>>) => {
    return convertDepartments(data);
  },
};
