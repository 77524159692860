import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'melp-design/components';
import { ModalProps, useModalContext } from 'store/modal';
import {
  useDeleteEmployeeBenefitAssignmentHistoryEntry,
  useEmployeeBenefitAssignmentHistory,
} from 'store/assignments';
import moment from 'moment';
import { Stack } from '@mui/material';
import { useConfirm } from 'store/confirm';
import { UpdateAssignmentModal } from 'containers/assignments';

interface Props extends ModalProps {
  employeeId: string;
  benefitId: string;
}

export const EmployeeBenefitAssignmentHistory: FC<Props> = ({
  employeeId,
  benefitId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const { confirmation } = useConfirm();

  const { data: history, isLoading } = useEmployeeBenefitAssignmentHistory(
    employeeId,
    benefitId,
  );

  const { mutate: deleteHistoryEntry, isLoading: isDeleting } =
    useDeleteEmployeeBenefitAssignmentHistoryEntry();

  return (
    <Modal.Content
      title={t('permissions.historyModule')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.close'),
        },
      ]}
    >
      <Table
        data={history ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'from',
            header: t('alternatives.dateRangeFrom'),
            cell: ({ row: { original: entry } }) => {
              return moment(entry.from).format('l');
            },
          },
          {
            id: 'to',
            header: t('common.to'),
            cell: ({ row: { original: entry } }) => {
              return entry.to ? moment(entry.to).format('l') : '-';
            },
          },
          {
            id: 'actions',
            cell: ({ row: { original: entry } }) => (
              <Stack flexDirection="row" gap={1} justifyContent="flex-end">
                <Button
                  label={t('common.edit')}
                  variant="neutral-outline"
                  size="sm"
                  onClick={() => {
                    openModal({
                      component: UpdateAssignmentModal,
                      props: {
                        assignmentId: entry.id,
                        relation: 'benefit/employee',
                        initialValues: {
                          from: entry.from,
                          to: entry.to,
                        },
                      },
                    });
                  }}
                />
                <Button
                  label={t('actions.delete')}
                  variant="danger-outline"
                  size="sm"
                  onClick={async () => {
                    const { confirmed } = await confirmation(
                      t('common.areYouSureToDelete', {
                        title: t('alternatives.dateRange'),
                      }),
                    );

                    if (confirmed) {
                      deleteHistoryEntry({ assignmentId: entry.id });
                    }
                  }}
                  disabled={isDeleting}
                />
              </Stack>
            ),
          },
        ]}
      />
    </Modal.Content>
  );
};
