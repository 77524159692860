import {
  IBaseBrand,
  IBenefitBrandWithSelection,
  IRecognitionBrandsConfig,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import { BaseBrand, convertBaseBrand } from 'store/brands';
import { loaders } from './benefit-brands.loaders';
import {
  BenefitBrandsConfig,
  BenefitBrandWithSelection,
} from './benefit-brands.types';

const convertBrandWithSelection = (
  brand: IBaseBrand & IBenefitBrandWithSelection,
): BaseBrand & BenefitBrandWithSelection => {
  return {
    ...convertBaseBrand(brand),
    isWhitelisted: brand.isWhitelisted,
    isAutoAssigned: brand.isAutoassigned,
  };
};

const convertBrands = (
  brands: (IBaseBrand & IBenefitBrandWithSelection)[],
): (BaseBrand & BenefitBrandWithSelection)[] => {
  return brands.map(convertBrandWithSelection);
};

const convertConfig = (
  config: IRecognitionBrandsConfig,
): BenefitBrandsConfig => {
  return {
    brands: config.brandIds ?? [],
    variant: config.type ?? [],
    primaryCategories: config.primaryCategory ?? [],
    providers: config.provider ?? [],
    countries: config.country ?? [],
    price:
      config.priceFrom || config.priceTo
        ? {
            from: config.priceFrom,
            to: config.priceTo,
          }
        : null,
  };
};

export const converters = {
  getBrands: ({ data }: Awaited<ReturnType<typeof loaders.getBrands>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBrands(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getConfig: ({ data }: Awaited<ReturnType<typeof loaders.getConfig>>) => {
    return convertConfig(data);
  },
};
