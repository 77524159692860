import { ComponentPropsWithoutRef, FC } from 'react';
import {
  ItemDetailsCard,
  Loader,
  Modal,
  NothingFoundAlert,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { useBrand } from 'store/brands';
import { useCompanyLanguages } from 'state/Administrators';
import { resolveTranslation } from 'utils/general';
import { richTextAsPlainText } from 'utils/richTextAsPlainText';
import {
  GridViewOutlined,
  PlaceOutlined,
  SellOutlined,
} from '@mui/icons-material';
import { formatCountry, formatList } from 'utils/format';

interface Props extends ModalProps {
  brandId: string;
  actions?: ComponentPropsWithoutRef<typeof Modal.Content>['actions'];
}

export const BrandDetailsModal: FC<Props> = ({
  closeModal,
  brandId,
  actions = [],
}) => {
  const { t, i18n } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const { data: brand, isLoading } = useBrand(brandId);

  if (isLoading) {
    return (
      <Modal.Content>
        <Loader />
      </Modal.Content>
    );
  }
  if (!brand) {
    return (
      <Modal.Content>
        <NothingFoundAlert />
      </Modal.Content>
    );
  }

  const translation = resolveTranslation(
    brand.content,
    i18n.language,
    defaultLanguage,
  );

  return (
    <Modal.Content
      plain
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.close'),
        },
        ...actions,
      ]}
    >
      <ItemDetailsCard
        title={brand.name}
        description={richTextAsPlainText(translation?.description ?? '')}
        image={brand.logo?.url}
        info={[
          {
            label: t('benefits.country'),
            icon: PlaceOutlined,
            content: formatList(
              i18n.language,
              brand.countries.map((alpha3) =>
                formatCountry(i18n.language, alpha3),
              ),
            ),
          },
          {
            label: t('marketplace.filtersItemCategory'),
            icon: GridViewOutlined,
            content: formatList(
              i18n.language,
              brand.categories.map((category) =>
                t(`marketplaceItems.itemCategory-${category}`),
              ),
            ),
          },
          {
            label: t('marketplaceItems.supplier'),
            icon: SellOutlined,
            content: formatList(i18n.language, brand.providers),
          },
        ]}
        // tags={{
        //   label: t('marketplace.prices'),
        //   items: getPrices(),
        // }}
      />
    </Modal.Content>
  );
};
