import { z } from 'zod';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { UploadedFile } from 'store/files';
import { Translations } from 'types/Common';
import { brandsFiltersSchema } from './brands.schemas';

export const brandVariants = ['gift_card', 'points', 'subscription'] as const;
export type BrandVariant = (typeof brandVariants)[number];

export const brandProviders = [
  'runa',
  'bamboo',
  'judu',
  'gera_dovana',
  'melp',
] as const;
export type BrandProvider = (typeof brandProviders)[number];

export const brandsSelections = ['all', 'selected', 'not-selected'] as const;
export type BrandsSelection = (typeof brandsSelections)[number];

export interface BaseBrand {
  id: string;
  sequence: number;
  name: string;
  slug: string;
  variant: BrandVariant;
  primaryCategory: ShopItemCategory | '';
  categories: ShopItemCategory[];
  logo: UploadedFile | null;
  content: Translations<{ description: string; shortDescription: string }>;
}

export interface Brand extends BaseBrand {
  secondaryCategory: ShopItemCategory | '';
  countries: string[];
  providers: BrandProvider[];
}

export interface BrandWithSelection {
  isSelected: boolean;
}

export type BrandsFilters = z.infer<typeof brandsFiltersSchema>;
