import { Box, Stack } from '@mui/material';
import { ComponentProps, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContentLanguage } from 'types/general';
import { Typography } from 'melp-design/components';
import { useSortedLanguages } from '../../../utils/useSortedLanguages';
import FormField from './FormField';
import { MultilingualProperty } from './Types';

interface Props extends ComponentProps<typeof FormField> {
  defaultValue?: MultilingualProperty;
  /**
   * The path to an array, which defined what languages should be displayed.
   * Default value "supportedLanguages"
   */
  languagesPropertyName?: string;
  /**
   * Text that explains the purpose of a field.
   */
  hint?: string;
  /**
   * Hides language labels. Even when a field is multilingual.
   */
  hideLanguageLabels?: boolean;
}

const MultilingualFormField = ({
  languagesPropertyName = 'supportedLanguages',
  hint,
  hideLanguageLabels = false,
  ...formFieldProps
}: Props) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();
  const supportedLanguages: Uppercase<ContentLanguage>[] = watch(
    languagesPropertyName,
    [],
  );
  const sortedLanguages = useSortedLanguages(supportedLanguages);

  if (!sortedLanguages.length) {
    return null;
  }

  const getName = (lang: Uppercase<ContentLanguage>) =>
    `${formFieldProps.name}.${lang}`;

  const getDefaultValue = (lang: Uppercase<ContentLanguage>) => {
    const predefinedDefaultValue =
      formFieldProps.render === 'imageUpload' ? null : '';
    return formFieldProps.defaultValue?.[lang] ?? predefinedDefaultValue;
  };

  const { label, render, ...rest } = formFieldProps;
  const multilingual = sortedLanguages.length > 1;

  const getRenderProp = () => {
    if (multilingual) {
      return render;
    }

    if (typeof render === 'string' && render === 'text') {
      return { type: render, props: { placeholder: hint } };
    }

    if (typeof render === 'object' && render.type === 'text') {
      return { ...render, props: { ...render.props, placeholder: hint } };
    }

    return render;
  };

  const showLanguageLabels = multilingual && !hideLanguageLabels;

  return (
    <Stack gap="10px" flexGrow={1}>
      {multilingual && !!label && (
        <div>
          <Typography variant="p2">{label}</Typography>
          <Typography variant="p2" color="textSecondary">
            {hint}
          </Typography>
        </div>
      )}
      <Box
        sx={
          multilingual
            ? {
                display: 'grid',
                gridTemplateColumns: showLanguageLabels ? 'auto 1fr' : '1fr',
                rowGap: '10px',
                columnGap: '15px',
                alignItems: 'center',
                paddingLeft: showLanguageLabels ? '5px' : 0,
              }
            : undefined
        }
      >
        {sortedLanguages.map((language) => {
          return (
            <Fragment key={language}>
              {showLanguageLabels && (
                <Typography variant="p2" color="textSecondary">
                  {t(`language.${language}`)}
                </Typography>
              )}
              <FormField
                {...rest}
                name={getName(language)}
                defaultValue={getDefaultValue(language)}
                label={multilingual ? undefined : label}
                render={getRenderProp()}
              />
            </Fragment>
          );
        })}
      </Box>
    </Stack>
  );
};

export default MultilingualFormField;
