import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './brands.loaders';
import { brandsKeys } from './brands.queries';

export const useAddBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.addBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(brandsKeys.all);
    },
  });
};

export const useRemoveBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(brandsKeys.all);
    },
  });
};

export const useBulkAddBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkAddBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(brandsKeys.all);
    },
  });
};

export const useBulkRemoveBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkRemoveBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(brandsKeys.all);
    },
  });
};
