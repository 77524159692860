import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './reactions.loaders';
import { converters } from './reactions.converters';
import { ReactionsFilters } from './reactions.types';

export const reactionsKeys = queryKeyFactory('reactions');

export const useReactions = (
  hostId: string,
  filters: Partial<ReactionsFilters> = {},
) => {
  return useQuery({
    queryKey: reactionsKeys.list(hostId, filters),
    queryFn: () => loaders.getReactions({ hostId, filters }),
    select: converters.getReactions,
    enabled: !!hostId,
  });
};

export const useInfiniteReactions = (
  hostId: string,
  filters: Partial<ReactionsFilters> = {},
) => {
  return useInfiniteQuery(
    reactionsKeys.list('infinite', hostId, filters),
    ({ pageParam = 1 }) =>
      loaders.getReactions({
        hostId,
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getReactions(page)),
        pageParams,
      }),
      enabled: !!hostId,
      keepPreviousData: true,
    },
  );
};
