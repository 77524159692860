import { ActionsMenu } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'store/confirm';
import { useModalContext } from 'store/modal';
import {
  BaseRecognition,
  useApproveRecognition,
  useDeleteRecognition,
  useEditRecognition,
} from 'store/recognitions';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import {
  EMOJI_BY_REACTION,
  reactionsVariants,
  useCreateReaction,
  useDeleteReaction,
} from 'store/reactions';
import { RecognitionThreadModal } from 'containers/recognitions';
import { RecognitionEditModal } from '..';

interface Props {
  recognition: BaseRecognition;
}

export const RecognitionActions: FC<Props> = ({ recognition }) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const { confirmation } = useConfirm();
  const pointsLabel = usePointsLabel();

  const { mutate: approveRecognition, isLoading: isApproving } =
    useApproveRecognition();
  const { mutate: editRecognition, isLoading: isEditing } =
    useEditRecognition();
  const { mutate: deleteRecognition, isLoading: isDeleting } =
    useDeleteRecognition();
  const { mutate: createReaction, isLoading: isCreatingReaction } =
    useCreateReaction();
  const { mutate: deleteReaction, isLoading: isDeletingReaction } =
    useDeleteReaction();

  return (
    <ActionsMenu
      type="compact"
      items={[
        ...(recognition.status === 'pending'
          ? ([
              {
                label: t('recognition.recognitionApprovalAction-approve'),
                onClick: () => {
                  approveRecognition({
                    recognitionId: recognition.id,
                    approval: true,
                  });
                },
                disabled: isApproving,
              },
              {
                label: t('recognition.recognitionApprovalAction-reject'),
                onClick: () => {
                  approveRecognition({
                    recognitionId: recognition.id,
                    approval: false,
                  });
                },
                disabled: isApproving,
                color: 'error',
              },
            ] as const)
          : recognition.type === 'companyToEmployee'
          ? ([
              {
                label: t('common.edit'),
                onClick: async () => {
                  const response = await openModal({
                    component: RecognitionEditModal,
                    props: {
                      comment: recognition.comment,
                      points: recognition.pointsReceived,
                    },
                  });

                  if (response.action === 'SAVE') {
                    editRecognition({
                      recognitionId: recognition.id,
                      points: response.points,
                      comment: response.comment,
                    });
                  }
                },
                disabled: isEditing,
              },
            ] as const)
          : []),
        {
          label: t('actions.comment'),
          onClick: () => {
            openModal({
              component: RecognitionThreadModal,
              props: {
                recognitionId: recognition.id,
              },
              size: 'xl',
            });
          },
        },
        ...(recognition.status !== 'pending'
          ? ([
              {
                label: t('actions.delete'),
                onClick: async () => {
                  const { confirmed } = await confirmation(
                    t('recognitions.delete.message', { pointsLabel }),
                    t('recognitions.delete.title'),
                    'danger',
                  );

                  if (confirmed) {
                    deleteRecognition({ recognitionId: recognition.id });
                  }
                },
                disabled: isDeleting,
                color: 'error',
              },
            ] as const)
          : []),
        ...(recognition.reactions.selected
          ? [
              null,
              {
                label: [
                  EMOJI_BY_REACTION[recognition.reactions.selected],
                  t(`reactions.variant.${recognition.reactions.selected}`),
                ].join(' '),
                onClick: () => {
                  deleteReaction({
                    hostId: recognition.id,
                  });
                },
                disabled: isDeletingReaction,
                color: 'primary',
              } as const,
            ]
          : [
              null,
              ...reactionsVariants.map((reaction) => ({
                label: [
                  EMOJI_BY_REACTION[reaction],
                  t(`reactions.variant.${reaction}`),
                ].join(' '),
                onClick: () => {
                  createReaction({
                    entity: 'recognition',
                    hostId: recognition.id,
                    emoji: reaction,
                  });
                },
                disabled: isCreatingReaction,
              })),
            ]),
      ]}
    />
  );
};
