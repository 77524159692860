import { Translations } from 'types/Common';
import { IFile, INewsBaseCategory } from 'types/income';
import { z } from 'zod';
import { EmbeddedReactions } from 'store/reactions';
import { ContentLanguage } from 'types/general';
import {
  newsCategoriesFiltersSchema,
  newsCommentsFiltersSchema,
  newsFiltersSchema,
} from './news.schemas';

export const newsStatuses = [
  'inactive',
  'published',
  'expiresSoon',
  'expired',
  'comingSoon',
  'draft',
] as const;

export type NewsStatus = (typeof newsStatuses)[number];

export const newsSendMethods = ['email', 'appNotification', 'none'] as const;

export type NewsSendMethod = (typeof newsSendMethods)[number];

export type NewTranslations = Translations<{
  title: string;
  content: string;
  headerImage?: IFile;
  attachments: IFile[];
}>;

export interface NewItem {
  id: string;
  name: string;
  status: NewsStatus;
  parentCompanyId: string;
  publishedAt: string | null;
  inactiveAt: string | null;
  sentCount: number;
  seenCount: number;
  commentsCount: number;
  sendMethods: NewsSendMethod[];
  supportedLanguages: Uppercase<ContentLanguage>[];
  translations: NewTranslations;
  pinned: boolean;
  pinnedUntil: string | null;
  allowComments: boolean;
  categories: INewsBaseCategory[];
  reactions: EmbeddedReactions;
}

export type NewsFilters = z.infer<typeof newsFiltersSchema>;

export type NewsCategoryTranslations = Translations<{ name: string }>;

export interface NewsCategory {
  id: string;
  name: string;
  translations: NewsCategoryTranslations;
  createdAt: string;
  updatedAt: string | null;
}

export type NewsCategoriesFilters = z.infer<typeof newsCategoriesFiltersSchema>;

export type NewsCommentsFilters = z.infer<typeof newsCommentsFiltersSchema>;
