import {
  Button,
  ListTotal,
  TableToolbar,
  TableWrapper,
  Table,
  TruncatedText,
  ReactionsPreview,
  Typography,
  Link,
  LinkAsButton,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DateRangeFilter,
  EmployeesFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import {
  COLOR_BY_STATUS,
  recognitionTypes,
  recognitionsFiltersSchema,
  useDownloadTemplate,
  useExportToXls,
  useRecognitions,
} from 'store/recognitions';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import { useSearchParams } from 'utils/navigation';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { useRecognitionAchievements } from 'store/recognition-achievements';
import { ReactionsModal } from 'containers/reactions';
import { RecognitionThreadModal } from 'containers/recognitions';
import { RecognitionActions, RecognitionsImportModal } from './atoms';

export const RecognitionsList = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    recognitionsFiltersSchema,
  );

  const { data: recognitions, isLoading } = useRecognitions(filters);

  const { data: achievements } = useRecognitionAchievements();
  const pointsLabel = usePointsLabel();

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();
  const { mutate: downloadTemplate, isLoading: isDownloadingTemplate } =
    useDownloadTemplate();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="15px"
          >
            <Button
              icon={FileDownloadOutlined}
              variant="secondary"
              onClick={() => downloadTemplate()}
              disabled={isDownloadingTemplate}
              tooltip={t('recognitions.actions.download_template.info')}
            />

            <Button
              label={t('actions.import')}
              onClick={() => {
                openModal({
                  component: RecognitionsImportModal,
                });
              }}
            />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={recognitions?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={recognitionsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('recognition.feedSender')}
                      value={value.sender}
                      initialValue={initialValues.sender}
                      onChange={(v) => setFilter('sender', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <EmployeesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('recognition.feedRecipient')}
                      value={value.recipient}
                      initialValue={initialValues.recipient}
                      onChange={(v) => setFilter('recipient', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <EmployeesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('integrations.type')}
                      value={value.type}
                      initialValue={initialValues.type}
                      onChange={(v) => setFilter('type', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={recognitionTypes.map((type) => ({
                            key: type,
                            label: t(`recognitions.type.${type}`),
                          }))}
                          searchable={false}
                          multiple={false}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('recognition.feedFiltersRecognitionDate')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'year']}
                          withTimezone
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('recognition.feedFiltersType')}
                      value={value.achievements}
                      initialValue={initialValues.achievements}
                      onChange={(v) => setFilter('achievements', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          multiple
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={
                            achievements?.map((achievement) => ({
                              key: achievement.id,
                              label: achievement.name,
                            })) ?? []
                          }
                        />
                      )}
                    </FilterButton>

                    <TextFilter
                      value={value.employee}
                      onApplyFilter={(v) => setFilter('employee', v)}
                      hint={t('recognition.recipientsListFiltersSearchHint')}
                    />
                  </>
                )}
              </Filters>

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={recognitions?.total ?? 0}
                exportToExcel={() => exportToXls({ filters })}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={recognitions?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'date',
            header: t('recognition.feedDate'),
            cell: ({ row: { original: recognition } }) =>
              moment(recognition.createdAt).format('l'),
            meta: { sort: true },
          },
          {
            id: 'sender',
            header: t('recognition.feedSender'),
            cell: ({ row: { original: recognition } }) =>
              recognition.sender ? (
                <Link
                  label={recognition.sender.fullName}
                  to={ROUTES.employees.details.replace(
                    ':id',
                    recognition.sender.id,
                  )}
                />
              ) : null,
          },
          {
            id: 'recipient',
            header: t('recognition.feedRecipient'),
            cell: ({ row: { original: recognition } }) =>
              recognition.employee ? (
                <Link
                  label={recognition.employee.fullName}
                  to={ROUTES.employees.details.replace(
                    ':id',
                    recognition.employee.id,
                  )}
                />
              ) : null,
          },
          {
            id: 'pointsReceived',
            header: pointsLabel,
            cell: ({ row: { original: recognition } }) =>
              recognition.pointsReceived,
            meta: { align: 'right' },
          },
          {
            id: 'achievement',
            header: t('recognition.feedAchievement'),
            cell: ({ row: { original: recognition } }) =>
              recognition.achievementTitle ? (
                recognition.achievement ? (
                  <Link
                    label={recognition.achievementTitle}
                    to={ROUTES.recognitions.achievements.details.replace(
                      ':id',
                      recognition.achievement.id,
                    )}
                  />
                ) : (
                  recognition.achievementTitle
                )
              ) : null,
          },
          {
            id: 'reactions',
            header: t('recognition.message.label'),
            cell: ({ row: { original: recognition } }) => (
              <Stack gap={0.25} alignItems="flex-start">
                <TruncatedText
                  text={recognition.comment}
                  maxLength={300}
                  textProps={{ color: 'inherit' }}
                />
                <ReactionsPreview
                  reactions={recognition.reactions.summary}
                  onClick={() => {
                    openModal({
                      component: ReactionsModal,
                      props: { hostId: recognition.id },
                    });
                  }}
                />
              </Stack>
            ),
          },
          {
            id: 'comments',
            header: '💬',
            cell: ({ row: { original: recognition } }) => {
              return (
                <LinkAsButton
                  label={recognition.commentsCount}
                  onClick={() => {
                    openModal({
                      component: RecognitionThreadModal,
                      props: {
                        recognitionId: recognition.id,
                      },
                      size: 'xl',
                    });
                  }}
                />
              );
            },
            meta: { align: 'right', sort: true },
          },
          {
            id: 'reactions',
            header: '👍',
            cell: ({ row: { original: recognition } }) => {
              return (
                <LinkAsButton
                  label={recognition.reactions.total}
                  onClick={() => {
                    openModal({
                      component: ReactionsModal,
                      props: { hostId: recognition.id },
                    });
                  }}
                />
              );
            },
            meta: { align: 'right', sort: true },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: recognition } }) =>
              recognition.status === 'not_required' ? null : (
                <Typography color={COLOR_BY_STATUS[recognition.status]}>
                  {t(
                    `recognition.recognitionApprovalStatus-${recognition.status}`,
                  )}
                </Typography>
              ),
          },
          {
            id: 'actions',
            cell: ({ row: { original: recognition } }) => {
              return <RecognitionActions recognition={recognition} />;
            },
          },
        ]}
        pagination={
          recognitions && {
            count: recognitions.total,
            page: recognitions.page,
            pageSize: recognitions.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
      />
    </TableWrapper>
  );
};
