import { ReactionsVariant } from './reactions.types';

export const EMOJI_BY_REACTION: Record<ReactionsVariant, string> = {
  emoji_like: '👍',
  emoji_celebrate: '👏',
  emoji_funny: '😄',
  emoji_love: '❤️',
  emoji_party: '🎉',
  emoji_curious: '🤔',
  emoji_cry: '😢',
  emoji_confused: '😕',
};
