import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './benefit-brands.loaders';
import { benefitBrandsKeys } from './benefit-brands.queries';

export const useAddBenefitBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.addBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};

export const useRemoveBenefitBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};

export const useBulkAddBenefitBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkAddBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};

export const useBulkRemoveBenefitBrands = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkRemoveBrands, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};

export const useUpdateBenefitBrandsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};

export const useRemoveBenefitBrandsConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeConfig, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitBrandsKeys.all);
    },
  });
};
