import { ConfirmationModal, ConfirmationOptions } from 'components/modals';
import { useModalContext } from 'store/modal';

export const useConfirm = () => {
  const { openModal } = useModalContext();

  const confirmation = async (
    prompt: ConfirmationOptions['prompt'],
    title?: ConfirmationOptions['title'],
    variant?: ConfirmationOptions['variant'],
  ): Promise<{ confirmed: boolean }> => {
    const { action } = await openModal({
      component: ConfirmationModal,
      props: { prompt, title, variant },
    });

    if (action === 'CONFIRM') return { confirmed: true };

    return { confirmed: false };
  };

  return {
    confirmation,
  };
};
