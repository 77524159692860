import { mutate } from 'swr';
import { Endpoints } from 'api/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authKeys } from 'store/auth';
import { loaders } from './companies.loaders';
import { companiesKeys } from './companies.queries';

export const useUpdateParentCompanySettings = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateParentCompanySettings, {
    onSuccess: () => {
      return queryClient.invalidateQueries(authKeys.all);
    },
  });
};

export const useCreateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createCompany, {
    onSuccess: () => {
      // TODO: remove when companies list is with react-query
      return Promise.all([
        mutate(Endpoints.company),
        queryClient.invalidateQueries(authKeys.all),
        queryClient.invalidateQueries(companiesKeys.all),
      ]);
    },
  });
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCompany, {
    onSuccess: () => {
      // TODO: remove when companies list is with react-query
      return Promise.all([
        mutate(Endpoints.company),
        queryClient.invalidateQueries(authKeys.all),
        queryClient.invalidateQueries(companiesKeys.all),
      ]);
    },
  });
};

export const useUpdateCompanySettings = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCompanySettings, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(authKeys.all),
        queryClient.invalidateQueries(companiesKeys.lists),
      ]);
    },
  });
};

export const useCreateCompanyContact = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createCompanyContact, {
    onSuccess: () => {
      return queryClient.invalidateQueries(companiesKeys.list('contacts'));
    },
  });
};

export const useUpdateCompanyContact = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCompanyContact, {
    onSuccess: () => {
      return queryClient.invalidateQueries(companiesKeys.list('contacts'));
    },
  });
};

export const useDeleteCompanyContact = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteCompanyContact, {
    onSuccess: () => {
      return queryClient.invalidateQueries(companiesKeys.list('contacts'));
    },
  });
};
