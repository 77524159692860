import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './brands.loaders';
import { converters } from './brands.converters';
import { BrandsFilters } from './brands.types';

export const brandsKeys = queryKeyFactory('brands');

export const useBrands = (filters: Partial<BrandsFilters> = {}) => {
  return useQuery({
    queryKey: brandsKeys.list(filters),
    queryFn: () => loaders.getBrands({ filters }),
    select: converters.getBrands,
  });
};

export const useInfiniteBrands = (filters: Partial<BrandsFilters> = {}) => {
  return useInfiniteQuery(
    brandsKeys.list('infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getBrands({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getBrands(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );
};

export const useBrand = (brandId: string) => {
  return useQuery({
    queryKey: brandsKeys.detail(brandId),
    queryFn: () => loaders.getBrand({ brandId }),
    select: converters.getBrand,
    enabled: !!brandId,
  });
};
