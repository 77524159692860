import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IBaseBrand,
  IRecognitionBrandsConfig,
  IRecognitionBrandWithSelection,
} from 'types/income';
import { getSorting } from 'utils/general';
import { RecognitionBrandsFilters } from './recognition-brands.types';

const getFilters = (filters: Partial<RecognitionBrandsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    type: filters.variant,
    brandIds: filters.brands,
    provider: filters.providers,
    country: filters.countries,
    primaryCategory: filters.primaryCategories,
    priceFrom: filters.price?.from,
    priceTo: filters.price?.to,
    isWhitelisted:
      filters.selection === 'selected'
        ? true
        : filters.selection === 'not-selected'
        ? false
        : undefined,
  },
});

interface GetBrands {
  filters: Partial<RecognitionBrandsFilters>;
}

interface AddBrand {
  brandId: string;
}

interface RemoveBrand {
  brandId: string;
}

interface BulkAddBrands {
  filters: Partial<RecognitionBrandsFilters>;
}

interface BulkRemoveBrands {
  filters: Partial<RecognitionBrandsFilters>;
}

interface UpdateConfig {
  config: Partial<RecognitionBrandsFilters>;
}

export const loaders = {
  getBrands: ({ filters }: GetBrands) => {
    return api<TableDataResponse<IBaseBrand & IRecognitionBrandWithSelection>>({
      url: Endpoints.clientAdmin.recognitions.brands.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  addBrand: ({ brandId }: AddBrand) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.byId.replace(
        ':brandId',
        brandId,
      ),
      method: 'post',
    });
  },
  removeBrand: ({ brandId }: RemoveBrand) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.byId.replace(
        ':brandId',
        brandId,
      ),
      method: 'delete',
    });
  },
  bulkAddBrands: ({ filters }: BulkAddBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.selection.bulk,
      params: availableFilters,
      method: 'post',
    });
  },
  bulkRemoveBrands: ({ filters }: BulkRemoveBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.selection.bulk,
      params: availableFilters,
      method: 'delete',
    });
  },
  getConfig: () => {
    return api<IRecognitionBrandsConfig>({
      url: Endpoints.clientAdmin.recognitions.brands.config.root,
    });
  },
  updateConfig: ({ config }: UpdateConfig) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.config.root,
      method: 'put',
      params: {
        filter: getFilters(config).filter,
      },
    });
  },
  removeConfig: () => {
    return api({
      url: Endpoints.clientAdmin.recognitions.brands.config.root,
      method: 'delete',
    });
  },
};
