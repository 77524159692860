import { ComponentPropsWithRef, FC, PropsWithChildren, ReactNode } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { Box } from '@mui/material';

interface Props {
  title?: string;
  actions?: Array<ComponentPropsWithRef<typeof Button>>;
  header?: ReactNode;
  footer?: ReactNode;
  plain?: boolean;
  dividers?: boolean;
}

export const ModalContent: FC<PropsWithChildren<Props>> = ({
  title,
  actions = [],
  header,
  footer,
  plain,
  dividers,
  children,
}) => {
  return (
    <>
      {plain ? null : title ? (
        <DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
      ) : (
        <div style={{ height: 30 }} />
      )}

      {header ? <Box sx={{ px: 3, pb: 2 }}>{header}</Box> : null}

      <DialogContent
        sx={{ ...(plain ? { p: 0 } : { p: 3 }) }}
        dividers={dividers}
      >
        {children}
      </DialogContent>

      {footer ? <Box sx={{ px: 3, pt: 2 }}>{footer}</Box> : null}

      {actions.length ? (
        <DialogActions
          sx={{
            p: 3,
            py: 2,
            position: 'sticky',
            bottom: 0,
            background: SystemColors.white,
          }}
        >
          {actions.map((action, i) => (
            <Button key={i} {...action} />
          ))}
        </DialogActions>
      ) : null}
    </>
  );
};
