import { FC, PropsWithChildren } from 'react';
import { Paper, PaperProps } from '@mui/material';
import { getBorder } from '../../../constants/Style';
import { Colors } from '../../style';

interface Props extends Pick<PaperProps, 'sx'> {
  sticky?: boolean;
}

const TableToolbar: FC<PropsWithChildren<Props>> = ({
  sx,
  sticky,
  children,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 1,
        borderRadius: 0,
        borderTop: 0,
        backgroundColor: Colors.white,
        ...(sticky
          ? {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }
          : {}),

        '&:first-child': {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          borderTop: getBorder(Colors.elementsGrey),
        },
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
};

export default TableToolbar;
