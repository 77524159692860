import { Fragment, ReactNode, useRef } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { SystemColors } from 'melp-design/style';
import { Search } from 'melp-design/icons';
import { Loader } from 'melp-design/components';
import { useBoundingClientRect } from 'utils/hooks';
import { Floater } from '../table/atoms';

interface Props<T> {
  data?: T[];
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  noDataMessage?: string;
  renderItem: (item: T) => ReactNode;
  selection?: {
    value: string[];
    onClear: () => void;
    actions?: Array<{ label: string; onClick: () => void }>;
    isLoading?: boolean;
  };
}

export const InfiniteList = <T extends { id: string }>({
  isLoading,
  data,
  hasNextPage = false,
  fetchNextPage,
  noDataMessage,
  renderItem,
  selection,
}: Props<T>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const rect = useBoundingClientRect(ref.current);

  if (isLoading) return <Loader />;
  if (!data?.length) {
    return (
      <Box sx={{ minHeight: 150, textAlign: 'center', padding: 3 }}>
        <Search
          style={{
            width: 40,
            height: 'auto',
            color: SystemColors.grey[45],
            marginBottom: 10,
          }}
        />
        <Typography variant="body1" color="textSecondary" textAlign="center">
          {noDataMessage ?? t('common.emptyListMessage')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }} ref={ref}>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          <Box sx={{ width: '100%', padding: 5, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        }
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 20,
        }}
      >
        {data.map((item) => (
          <Fragment key={item.id}>{renderItem(item)}</Fragment>
        ))}
      </InfiniteScroll>

      {selection?.actions && selection.value.length ? (
        <Floater
          selectedItems={selection.value}
          actions={selection.actions}
          onClear={selection.onClear}
          isLoading={selection.isLoading}
          offsetLeft={rect?.x}
        />
      ) : null}
    </Box>
  );
};
