import { useMemo } from 'react';
import {
  InfiniteList,
  TableWrapper,
  TableToolbar,
  Tabs,
  ListCard,
  Button,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BrandsFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import { usePredefinedToasts } from 'utils/Toast';
import { useSearchParams } from 'utils/navigation';
import {
  benefitBrandsFiltersSchema,
  useAddBenefitBrand,
  useBenefitBrandsConfig,
  useBulkAddBenefitBrands,
  useBulkRemoveBenefitBrands,
  useInfiniteBenefitBrands,
  useRemoveBenefitBrand,
} from 'store/benefit-brands';
import { shopItemCategories } from 'types/MarketplaceItems';
import { APP, ROUTES } from 'config';
import { formatCountry } from 'utils/format';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import { brandProviders } from 'store/brands';
import {
  BrandDetailsModal,
  BrandsDisabledFeatureAlert,
} from 'containers/brands';
import { richTextAsPlainText } from 'utils/richTextAsPlainText';
import { useModalContext } from 'store/modal';
import { calculateFiltersTotal } from 'utils/Filters';
import { useRouteParams } from 'utils/useIdParam';
import { RewardsConfigModal } from './atoms';

export const BenefitBrandsList = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { id: benefitId } = useRouteParams(ROUTES.benefits.details);
  const { defaultLanguage } = useCompanyLanguages();
  const { openModal, closeModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    benefitBrandsFiltersSchema,
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isPreviousData,
    isLoading,
  } = useInfiniteBenefitBrands(benefitId, filters);
  const { data: config } = useBenefitBrandsConfig(benefitId);

  const brands = useMemo(() => {
    return {
      items: (data?.pages.map(({ items }) => items) ?? []).flat(),
      total: data?.pages[0]?.total,
    };
  }, [data]);

  const {
    mutate: addBrand,
    isLoading: isAdding,
    variables: addBrandVariables,
  } = useAddBenefitBrand();
  const {
    mutate: removeBrand,
    isLoading: isRemoving,
    variables: removeBrandVariables,
  } = useRemoveBenefitBrand();
  const { mutate: bulkAddBrands, isLoading: isBulkAdding } =
    useBulkAddBenefitBrands();
  const { mutate: bulkRemoveBrands, isLoading: isBulkRemoving } =
    useBulkRemoveBenefitBrands();

  const { selection, ...baseFilters } = filters;

  return (
    <Stack gap={2}>
      <BrandsDisabledFeatureAlert />

      <TableWrapper>
        <TableToolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Tabs
              value={selection}
              onChange={(v) => navigate({ params: { selection: v } })}
              items={[
                {
                  value: 'all',
                  label: t('marketplace.all'),
                },
                {
                  value: 'selected',
                  label: t('marketplace.selected'),
                },
              ]}
            />

            <Stack direction="row" alignItems="center" gap={1}>
              {config ? (
                <Button
                  label={t('recognitions.rewards.actions.set_config')}
                  variant="tertiary"
                  size="sm"
                  onClick={() => {
                    openModal({
                      component: RewardsConfigModal,
                      size: 'lg',
                      props: {
                        benefitId,
                        config: {
                          providers: config.providers,
                          primaryCategories: config.primaryCategories,
                          countries: config.countries,
                          price: config.price,
                        },
                      },
                    });
                  }}
                  badge={calculateFiltersTotal(config ?? {})}
                />
              ) : null}
              <FiltersInModal
                value={baseFilters}
                initialValues={benefitBrandsFiltersSchema.parse({
                  selection,
                })}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('brands.title')}
                      value={value.brands}
                      initialValue={initialValues.brands}
                      onChange={(v) => setFilter('brands', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <BrandsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('brands.primary_category.label')}
                      value={value.primaryCategories}
                      initialValue={initialValues.primaryCategories}
                      onChange={(v) => setFilter('primaryCategories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="primaryCategories"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('providers.title')}
                      value={value.providers}
                      initialValue={initialValues.providers}
                      onChange={(v) => setFilter('providers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="providers"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={brandProviders.map((provider) => ({
                            key: provider,
                            label: provider,
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplace.filtersPriceRange')}
                      value={value.price}
                      initialValue={initialValues.price}
                      onChange={(v) => setFilter('price', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <NumberRangeFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>

              {brands.total ? (
                <>
                  <Divider orientation="vertical" variant="middle" flexItem />

                  {selection === 'all' ? (
                    <Button
                      label={t('marketplace.selectFilteredItems', {
                        count: brands.total,
                      })}
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        bulkAddBrands(
                          { benefitId, filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isBulkAdding ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : selection === 'selected' ? (
                    <Button
                      label={t('marketplace.removeFilteredItems', {
                        count: brands.total,
                      })}
                      variant="danger-outline"
                      size="sm"
                      onClick={() =>
                        bulkRemoveBrands(
                          { benefitId, filters: baseFilters },
                          {
                            onSuccess: () => {
                              predefinedToasts.success.updated();
                            },
                          },
                        )
                      }
                      disabled={
                        isLoading ||
                        isBulkRemoving ||
                        !!(isFetching && isPreviousData)
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Stack>
          </Stack>
        </TableToolbar>

        <TableWrapper.InfiniteListWrapper>
          <InfiniteList
            data={brands.items}
            renderItem={(brand) => {
              const translation = resolveTranslation(
                brand.content,
                i18n.language,
                defaultLanguage,
              );
              return (
                <ListCard
                  title={brand.name}
                  description={richTextAsPlainText(
                    translation?.description ?? '',
                  )}
                  image={brand.logo?.url}
                  // label={getPriceLabel(brand)}
                  onClick={() => {
                    openModal({
                      component: BrandDetailsModal,
                      props: {
                        brandId: brand.id,
                        actions: [
                          brand.isWhitelisted
                            ? {
                                label: t('marketplace.removeItem'),
                                variant: 'danger-fill',
                                onClick: () => {
                                  removeBrand(
                                    { benefitId, brandId: brand.id },
                                    {
                                      onSuccess: () => {
                                        closeModal();
                                        predefinedToasts.success.updated();
                                      },
                                    },
                                  );
                                },
                                disabled: isRemoving,
                              }
                            : {
                                label: t('marketplace.selectItem'),
                                variant: 'primary',
                                onClick: () => {
                                  addBrand(
                                    { benefitId, brandId: brand.id },
                                    {
                                      onSuccess: () => {
                                        closeModal();
                                        predefinedToasts.success.updated();
                                      },
                                    },
                                  );
                                },
                                disabled: isAdding,
                              },
                        ],
                      },
                      size: 'lg',
                    });
                  }}
                  action={
                    brand.isWhitelisted
                      ? {
                          label: t('marketplace.removeItem'),
                          variant: 'danger-outline',
                          onClick: () => {
                            removeBrand(
                              { benefitId, brandId: brand.id },
                              {
                                onSuccess: () => {
                                  predefinedToasts.success.updated();
                                },
                              },
                            );
                          },
                          disabled:
                            isRemoving &&
                            removeBrandVariables?.brandId === brand.id,
                        }
                      : {
                          label: t('marketplace.selectItem'),
                          variant: 'secondary',
                          onClick: () => {
                            addBrand(
                              { benefitId, brandId: brand.id },
                              {
                                onSuccess: () => {
                                  predefinedToasts.success.updated();
                                },
                              },
                            );
                          },
                          disabled:
                            isAdding && addBrandVariables?.brandId === brand.id,
                        }
                  }
                />
              );
            }}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            noDataMessage={[
              t('recognition.rewardsNoItemsTitle'),
              t('recognition.rewardsNoItemsSubtitle'),
            ].join(' ')}
          />
        </TableWrapper.InfiniteListWrapper>
      </TableWrapper>
    </Stack>
  );
};
