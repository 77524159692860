import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './benefit-brands.loaders';
import { converters } from './benefit-brands.converters';
import { BenefitBrandsFilters } from './benefit-brands.types';

export const benefitBrandsKeys = queryKeyFactory('benefit-brands');

export const useBenefitBrands = (
  benefitId: string,
  filters: Partial<BenefitBrandsFilters> = {},
) => {
  return useQuery({
    queryKey: benefitBrandsKeys.list(benefitId, filters),
    queryFn: () => loaders.getBrands({ benefitId, filters }),
    select: converters.getBrands,
    enabled: !!benefitId,
  });
};

export const useInfiniteBenefitBrands = (
  benefitId: string,
  filters: Partial<BenefitBrandsFilters> = {},
) => {
  return useInfiniteQuery(
    benefitBrandsKeys.list('infinite', benefitId, filters),
    ({ pageParam = 1 }) =>
      loaders.getBrands({
        benefitId,
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getBrands(page)),
        pageParams,
      }),
      enabled: !!benefitId,
      keepPreviousData: true,
    },
  );
};

export const useBenefitBrandsConfig = (benefitId: string) => {
  return useQuery({
    queryKey: benefitBrandsKeys.detail('config', benefitId),
    queryFn: () => loaders.getConfig({ benefitId }),
    select: converters.getConfig,
  });
};
