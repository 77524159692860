import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IDiscount } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { DiscountsFilters } from './discounts.types';

interface GetDiscounts {
  filters: Partial<DiscountsFilters>;
}

interface BulkUpdate {
  enabled?: string[];
  disabled?: string[];
}

export const loaders = {
  getDiscounts: ({ filters }: GetDiscounts) => {
    return api<TableDataResponse<IDiscount>>({
      url: Endpoints.clientAdmin.discounts.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
          status: filters.status,
          discountId: filters.discounts,
          countries: filters.countries,
          cities: filters.cities,
          supplierId: filters.suppliers,
          categories: filters.categories,
          publishDateFrom: filters.publishDate?.from,
          publishDateTo: filters.publishDate?.to,
        },
        sort: filters.sort
          ? `${filters.sort.order === 'desc' ? '-' : ''}${
              filters.sort.columnKey
            }`
          : undefined,
      },
    });
  },
  bulkUpdate: ({ enabled, disabled }: BulkUpdate) => {
    return api({
      url: Endpoints.clientAdmin.discounts.bulkUpdate,
      data: {
        isEnabledIds: enabled ?? [],
        isDisabledIds: disabled ?? [],
      },
      method: 'patch',
    });
  },
};
