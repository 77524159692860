import { z } from 'zod';
import { UploadedFile } from 'store/files';
import { Translations } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { discountsFiltersSchema } from './discounts.schemas';

export type DiscountTranslations = Translations<{
  title: string;
  teaser: string;
}>;

export const discountStatuses = [
  'draft',
  'active',
  'inactive',
  'expired',
] as const;
export type DiscountStatus = (typeof discountStatuses)[number];

export const availabilityStatuses = ['enabled', 'disabled'] as const;
export type AvailabilityStatus = (typeof availabilityStatuses)[number];

export interface Discount {
  id: string;
  name: string;
  supplier: {
    id: string;
    name: string;
    logo: UploadedFile | null;
    contact: string;
  };
  priority: number;
  status: DiscountStatus;
  isEnabled: boolean;
  image: UploadedFile;
  countries: string[];
  cities: string[];
  categories: ShopItemCategory[];
  validFrom: string | null;
  validTo: string | null;
  publishedAt: string;
  translations: Translations<{
    title: string;
    teaser: string;
    description: string;
    promo: string;
  }>;
}

export type DiscountsFilters = z.infer<typeof discountsFiltersSchema>;
