import { FC } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import {
  Loader,
  ReactionsPreview,
  TruncatedText,
  Typography,
} from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import moment from 'moment';
import { t } from 'i18next';
import { Comment } from 'store/comments';
import { ReactionsModal } from 'containers/reactions';
import { useModalContext } from 'store/modal';

interface Props {
  comment: Comment;
  actions: Array<{
    label: string;
    onClick: () => void;
    loading?: boolean;
    variant?: 'regular' | 'primary' | 'danger';
  }>;
}

export const CommentBlock: FC<Props> = ({ comment, actions }) => {
  const { openModal } = useModalContext();

  const hidden = comment.status === 'hidden';

  return (
    <Stack gap={0.75} sx={{ width: '100%' }}>
      <Stack
        sx={{
          backgroundColor: SystemColors.grey[90],
          p: 1,
          borderRadius: 1,
          opacity: hidden ? 0.6 : undefined,
        }}
        alignSelf="flex-start"
      >
        <Typography variant="h4">
          {comment.owner?.fullName ?? t('marketplace.employeeDeleted')}
        </Typography>
        <TruncatedText text={comment.content} maxLength={300} />
      </Stack>

      <Stack gap={0.25} px={0.25}>
        {hidden ? (
          <Typography color="textSecondary">
            {t('comments.hidden.message')}
          </Typography>
        ) : null}

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={4}
        >
          <Stack flexDirection="row" columnGap={2} rowGap={0.5} flexWrap="wrap">
            {comment.date ? (
              <Typography color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
                {moment
                  .duration(moment(comment.date).diff(moment(new Date())))
                  .humanize(true)}
              </Typography>
            ) : null}

            {actions.map(
              ({ label, onClick, loading, variant = 'regular' }, i) => (
                <ButtonBase
                  onClick={() => onClick()}
                  disabled={loading}
                  disableRipple
                  key={i}
                >
                  <Stack
                    flexDirection="row"
                    columnGap={0.25}
                    alignItems="center"
                  >
                    {loading ? <Loader size={15} /> : null}
                    <Typography
                      color={
                        variant === 'regular'
                          ? 'textSecondary'
                          : variant === 'primary'
                          ? 'primary'
                          : variant === 'danger'
                          ? 'error'
                          : undefined
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {label}
                    </Typography>
                  </Stack>
                </ButtonBase>
              ),
            )}
          </Stack>

          <ReactionsPreview
            reactions={comment.reactions.summary}
            onClick={() => {
              openModal({
                component: ReactionsModal,
                props: { hostId: comment.id },
              });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
