import { FC, Fragment, ReactElement } from 'react';
import { Typography } from 'melp-design/components';
import { Box, Stack, Tooltip } from '@mui/material';
import { ContentLanguage } from 'types/general';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';
import { SystemColors } from 'melp-design/style';
import { formatLanguage } from 'utils/format';

interface Props {
  label?: string;
  info?: string;
  required?: boolean;
  fields: Array<{
    id: string;
    language: Uppercase<ContentLanguage>;
    component: ReactElement;
  }>;
}

export const MultiLanguageInputContainer: FC<Props> = ({
  label,
  info,
  required,
  fields,
}) => {
  const { i18n } = useTranslation();

  return (
    <Stack gap={1}>
      {label ? (
        <Stack direction="row" alignItems="center">
          <Typography variant="p2">
            {[label, required ? '*' : null].filter(Boolean).join(' ')}
          </Typography>
          {info ? (
            <Tooltip title={info}>
              <HelpOutline sx={{ color: SystemColors.grey[55], height: 16 }} />
            </Tooltip>
          ) : null}
        </Stack>
      ) : null}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          rowGap: 1,
          columnGap: 2,
          alignItems: 'center',
        }}
      >
        {fields.map((field) => (
          <Fragment key={field.id}>
            <Typography variant="p2" color="textSecondary">
              {formatLanguage(i18n.language, field.language)}
            </Typography>
            {field.component}
          </Fragment>
        ))}
      </Box>
    </Stack>
  );
};
