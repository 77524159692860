import { Button, Modal } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import {
  EMOJI_BY_REACTION,
  ReactionsVariant,
  reactionsVariants,
} from 'store/reactions';
import { Stack } from '@mui/material';

interface Props extends ModalProps {
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'SELECT'; reaction: ReactionsVariant },
  ) => void;
}

export const ReactionSelectModal: FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal.Content title={t('reactions.title')}>
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        flexWrap="wrap"
        gap={1}
      >
        {reactionsVariants.map((reaction) => (
          <Button
            label={EMOJI_BY_REACTION[reaction]}
            variant="secondary"
            onClick={() => {
              closeModal({ action: 'SELECT', reaction });
            }}
            key={reaction}
          />
        ))}
      </Stack>
    </Modal.Content>
  );
};
