import { IBaseBrand, IBrand, IBrandWithSelection } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertUploadedFile } from 'store/files';
import { isDefined } from 'utils/isDefined';
import { loaders } from './brands.loaders';
import { BaseBrand, Brand, BrandWithSelection } from './brands.types';

const convertBrandWithSelection = (
  brand: IBaseBrand & IBrandWithSelection,
): BaseBrand & BrandWithSelection => {
  return {
    ...convertBaseBrand(brand),
    isSelected: brand.isSelected,
  };
};

export const convertBaseBrand = (brand: IBaseBrand): BaseBrand => {
  return {
    id: brand.id,
    sequence: brand.sequence,
    name: brand.name,
    slug: brand.slug ?? '',
    variant: brand.type,
    primaryCategory: brand.primaryCategory ?? '',
    categories: [brand.primaryCategory || null].filter(isDefined),
    logo: brand.logo?.id
      ? convertUploadedFile(brand.logo)
      : brand.logoHref
      ? { id: '', mime: '', name: '', url: brand.logoHref }
      : null,
    content: brand.content.length
      ? brand.content.map((lang) => ({
          language: lang.language,
          description: lang.description,
          shortDescription: lang.shortDescription,
        }))
      : brand.language
      ? [
          {
            language: brand.language,
            description: brand.description ?? '',
            shortDescription: brand.shortDescription ?? '',
          },
        ]
      : [],
  };
};

const convertBrand = (brand: IBrand): Brand => {
  return {
    ...convertBaseBrand(brand),
    secondaryCategory: brand.secondaryCategory ?? '',
    countries: brand.countries ?? [],
    providers: brand.providers ?? [],
    categories: [
      brand.primaryCategory || null,
      brand.secondaryCategory || null,
    ].filter(isDefined),
  };
};

const convertBrands = (
  brands: (IBaseBrand & IBrandWithSelection)[],
): (BaseBrand & BrandWithSelection)[] => {
  return brands.map(convertBrandWithSelection);
};

export const converters = {
  getBrands: ({ data }: Awaited<ReturnType<typeof loaders.getBrands>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBrands(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getBrand: ({ data }: Awaited<ReturnType<typeof loaders.getBrand>>) => {
    return convertBrand(data);
  },
};
