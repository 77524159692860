import { FC } from 'react';
import {
  ActionsMenu,
  LinkAsButton,
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
  ReactionsPreview,
  TruncatedText,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DateRangeFilter,
  EmployeesFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import { APP, ROUTES } from 'config';
import {
  recognitionsCommentsFiltersSchema,
  useRecognitionsComments,
} from 'store/recognitions';
import { useModalContext } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import { useSearchParams } from 'utils/navigation';
import {
  COLOR_BY_STATUS,
  commentStatuses,
  useDeleteComments,
  useUpdateCommentsStatus,
} from 'store/comments';
import {
  EMOJI_BY_REACTION,
  reactionsVariants,
  useCreateReaction,
  useDeleteReaction,
} from 'store/reactions';
import TextFilter from 'components/filters/TextFilter';
import { ReactionsModal } from 'containers/reactions';
import { useConfirm } from 'store/confirm';
import { useMe } from 'state/Administrators';
import {
  CommentEditModal,
  CommentsDisabledFeatureAlert,
} from 'containers/comments';
import { RecognitionThreadModal } from '../atoms';

export const RecognitionsCommentsList: FC = () => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();
  const { confirmation } = useConfirm();
  const { me } = useMe();

  const { searchParams: filters, navigate } = useSearchParams(
    recognitionsCommentsFiltersSchema,
  );

  const { data: comments, isLoading } = useRecognitionsComments(filters);

  const { mutate: updateCommentsStatus, isLoading: isUpdatingStatus } =
    useUpdateCommentsStatus();
  const { mutate: deleteComments, isLoading: isDeleting } = useDeleteComments();
  const { mutate: createReaction, isLoading: isCreatingReaction } =
    useCreateReaction();
  const { mutate: deleteReaction, isLoading: isDeletingReaction } =
    useDeleteReaction();

  return (
    <Stack gap={1}>
      <CommentsDisabledFeatureAlert />

      <TableWrapper>
        <TableToolbar>
          <Stack gap={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <ListTotal total={comments?.total} />

              <Stack direction="row" alignItems="center" gap={1}>
                <Filters
                  value={filters}
                  initialValues={recognitionsCommentsFiltersSchema.parse({})}
                  onChange={(v) => navigate({ params: v })}
                >
                  {({ value, initialValues, setFilter }) => (
                    <>
                      <FilterButton
                        label={t('common.status')}
                        value={value.status}
                        initialValue={initialValues.status}
                        onChange={(v) => setFilter('status', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                            options={commentStatuses.map((status) => ({
                              key: status,
                              label: t(`status.${status}`),
                            }))}
                            searchable={false}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('common.date')}
                        value={value.date}
                        initialValue={initialValues.date}
                        onChange={(v) => setFilter('date', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <DateRangeFilter
                            value={value}
                            onApply={applyFilter}
                            onClear={clearFilter}
                            onCancel={close}
                            suggestions={['week', 'month', 'year']}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('comments.commenter.label')}
                        value={value.employees}
                        initialValue={initialValues.employees}
                        onChange={(v) => setFilter('employees', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <EmployeesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('reactions.title')}
                        value={value.reactions}
                        initialValue={initialValues.reactions}
                        onChange={(v) => setFilter('reactions', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            multiple
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            options={reactionsVariants.map((reaction) => ({
                              label: EMOJI_BY_REACTION[reaction],
                              key: reaction,
                            }))}
                            searchable={false}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('recognition.feedSender')}
                        value={value.sender}
                        initialValue={initialValues.sender}
                        onChange={(v) => setFilter('sender', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <EmployeesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('recognition.feedRecipient')}
                        value={value.recipient}
                        initialValue={initialValues.recipient}
                        onChange={(v) => setFilter('recipient', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <EmployeesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <TextFilter
                        value={value.search}
                        onApplyFilter={(v) => setFilter('search', v)}
                        placeholder={t('common.search')}
                      />
                    </>
                  )}
                </Filters>
              </Stack>
            </Stack>
          </Stack>
        </TableToolbar>

        <Table
          data={comments?.items ?? []}
          isLoading={isLoading}
          stickyHeader
          columns={[
            {
              id: 'status',
              header: t('common.status'),
              cell: ({ row: { original: comment } }) => {
                return (
                  <Typography color={COLOR_BY_STATUS[comment.status]}>
                    {t(`status.${comment.status}`)}
                  </Typography>
                );
              },
            },
            {
              id: 'date',
              header: t('common.date'),
              cell: ({ row: { original: comment } }) =>
                moment(comment.date).format('l'),
              meta: { sort: true },
            },
            {
              id: 'owner',
              header: t('comments.commenter.label'),
              cell: ({ row: { original: comment } }) => {
                if (comment.owner?.type === 'employee') {
                  return comment.owner.id ? (
                    <Link
                      label={comment.owner.fullName}
                      to={ROUTES.employees.details.replace(
                        ':id',
                        comment.owner.id,
                      )}
                    />
                  ) : (
                    <Typography variant="p1" color="textSecondary">
                      {t('marketplace.employeeDeleted')}
                    </Typography>
                  );
                }

                if (comment.owner?.type === 'admin') {
                  return comment.owner.id ? (
                    <Link
                      label={comment.owner.fullName}
                      to={`${ROUTES.administrators.details.replace(
                        ':id',
                        comment.owner.id,
                      )}?tab=administratorsInformation`}
                    />
                  ) : (
                    comment.owner.fullName
                  );
                }

                return null;
              },
            },
            {
              id: 'comment',
              header: t('clients.comment'),
              cell: ({ row: { original: comment } }) => (
                <Stack gap={0.25} alignItems="flex-start">
                  <TruncatedText
                    text={comment.content}
                    maxLength={300}
                    textProps={{ color: 'inherit' }}
                  />
                  <ReactionsPreview
                    reactions={comment.reactions.summary}
                    onClick={() => {
                      openModal({
                        component: ReactionsModal,
                        props: { hostId: comment.id },
                      });
                    }}
                  />
                </Stack>
              ),
            },
            {
              id: 'subject',
              header: t('comments.linked_to.label'),
              cell: ({ row: { original: comment } }) => (
                <Box minWidth={250}>
                  <LinkAsButton
                    label={[
                      comment.recognition.sender?.fullName ||
                        t('marketplace.employeeDeleted'),
                      comment.recognition.recipient?.fullName ||
                        t('marketplace.employeeDeleted'),
                    ].join(' ▸ ')}
                    onClick={() => {
                      openModal({
                        component: RecognitionThreadModal,
                        props: {
                          recognitionId: comment.subject.id,
                        },
                        size: 'xl',
                      });
                    }}
                  />

                  <TruncatedText
                    text={comment.recognition.message}
                    maxLength={50}
                    textProps={{ color: 'inherit' }}
                  />
                </Box>
              ),
            },
            {
              id: 'actions',
              cell: ({ row: { original: comment } }) => {
                return (
                  <ActionsMenu
                    type="compact"
                    items={[
                      ...(me?.id === comment.owner?.id
                        ? [
                            {
                              label: t('common.edit'),
                              onClick: () => {
                                openModal({
                                  component: CommentEditModal,
                                  props: {
                                    commentId: comment.id,
                                    initialComment: comment.content,
                                  },
                                });
                              },
                            },
                          ]
                        : []),
                      {
                        ...(comment.status === 'visible'
                          ? {
                              label: t('common.hide'),
                              onClick: () => {
                                updateCommentsStatus(
                                  { status: 'hidden', ids: [comment.id] },
                                  {
                                    onSuccess: () => {
                                      predefinedToasts.success.updated();
                                    },
                                  },
                                );
                              },
                            }
                          : {
                              label: t('common.unhide'),
                              onClick: () => {
                                updateCommentsStatus(
                                  { status: 'visible', ids: [comment.id] },
                                  {
                                    onSuccess: () => {
                                      predefinedToasts.success.updated();
                                    },
                                  },
                                );
                              },
                            }),
                        disabled: isUpdatingStatus,
                      },
                      {
                        label: t('actions.delete'),
                        onClick: async () => {
                          const { confirmed } = await confirmation(
                            t('comments.delete.message'),
                            undefined,
                            'danger',
                          );

                          if (confirmed) {
                            deleteComments(
                              { ids: [comment.id] },
                              {
                                onSuccess: () => {
                                  predefinedToasts.success.deleted();
                                },
                              },
                            );
                          }
                        },
                        color: 'error',
                        disabled: isDeleting,
                      },
                      ...(comment.reactions.selected
                        ? [
                            {
                              label: [
                                EMOJI_BY_REACTION[comment.reactions.selected],
                                t(
                                  `reactions.variant.${comment.reactions.selected}`,
                                ),
                              ].join(' '),
                              onClick: () => {
                                deleteReaction({
                                  hostId: comment.id,
                                });
                              },
                              disabled: isDeletingReaction,
                              color: 'primary',
                            } as const,
                          ]
                        : [
                            null,
                            ...reactionsVariants.map((reaction) => ({
                              label: [
                                EMOJI_BY_REACTION[reaction],
                                t(`reactions.variant.${reaction}`),
                              ].join(' '),
                              onClick: () => {
                                createReaction({
                                  entity: 'comment',
                                  hostId: comment.id,
                                  emoji: reaction,
                                });
                              },
                              disabled: isCreatingReaction,
                            })),
                          ]),
                    ]}
                  />
                );
              },
            },
          ]}
          rows={{
            inactive: ({ original: comment }) => comment.status === 'hidden',
          }}
          pagination={
            comments && {
              count: comments.total,
              page: filters.page,
              pageSize: filters.pageSize,
              pageSizeOptions: APP.pagination.defaults.pageSize,
              onChange: (page, pageSize) => {
                navigate({ params: { page, pageSize } });
              },
            }
          }
          sort={{
            value: filters.sort,
            onChange: (v) => navigate({ params: { sort: v } }),
          }}
          selection={{
            value: selectedRowIds,
            onChange: setSelectedRowIds,
            actions: [
              {
                label: t('common.hide'),
                onClick: () => {
                  updateCommentsStatus(
                    { status: 'hidden', ids: Object.keys(selectedRowIds) },
                    {
                      onSuccess: () => {
                        predefinedToasts.success.updated();
                        setSelectedRowIds({});
                      },
                    },
                  );
                },
              },
              {
                label: t('common.unhide'),
                onClick: () => {
                  updateCommentsStatus(
                    { status: 'visible', ids: Object.keys(selectedRowIds) },
                    {
                      onSuccess: () => {
                        predefinedToasts.success.updated();
                        setSelectedRowIds({});
                      },
                    },
                  );
                },
              },
              {
                label: t('actions.delete'),
                onClick: async () => {
                  const { confirmed } = await confirmation(
                    t('comments.delete.message'),
                  );

                  if (confirmed) {
                    deleteComments(
                      { ids: Object.keys(selectedRowIds) },
                      {
                        onSuccess: () => {
                          predefinedToasts.success.deleted();
                          setSelectedRowIds({});
                        },
                      },
                    );
                  }
                },
              },
            ],
            isLoading: isUpdatingStatus || isDeleting,
          }}
        />
      </TableWrapper>
    </Stack>
  );
};
