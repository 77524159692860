import { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';

interface Props {
  id: string;
  disabled?: boolean;
}

export const SortableItem: FC<Props> = ({ id, disabled, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  return (
    <li
      ref={setNodeRef}
      style={{
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
        transition,
        cursor: disabled ? undefined : 'grab',
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </li>
  );
};
