import { FC } from 'react';
import { Alert, ExternalLink } from 'melp-design/components';
import { useMe } from 'state/Administrators';
import { Trans } from 'react-i18next';
import { APP } from 'config';

export const CommentsDisabledFeatureAlert: FC = () => {
  const { isFeatureEnabled } = useMe();

  return isFeatureEnabled('comments') ? null : (
    <Alert severity="warning">
      <Trans
        i18nKey="comments.info.disabled_feature"
        values={{ email: APP.email.info }}
        components={[
          <ExternalLink
            label={APP.email.info}
            href={`mailto:${APP.email.info}`}
            key="email"
          />,
        ]}
      />
    </Alert>
  );
};
