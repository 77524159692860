import { FC } from 'react';
import { Modal, NumberInput, TextField } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { Box, Stack } from '@mui/material';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';

interface Values {
  points: number;
  comment: string;
}

interface Props extends ModalProps {
  points: number;
  comment: string;
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'SAVE'; points: number; comment: string },
  ) => void;
}

export const RecognitionEditModal: FC<Props> = ({
  closeModal,
  points,
  comment,
}) => {
  const { t } = useTranslation();
  const pointsLabel = usePointsLabel();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      points,
      comment,
    },
  });

  const onSubmit: SubmitHandler<Values> = async (values) => {
    closeModal({
      action: 'SAVE',
      points: values.points,
      comment: values.comment,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('recognition.recognition')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
          },
        ]}
      >
        <Stack gap={2}>
          <Box width={100}>
            <Controller
              name="points"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <NumberInput
                  label={pointsLabel}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  key={value}
                  ref={ref}
                />
              )}
            />
          </Box>

          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('recognitions.form.comment.label')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
