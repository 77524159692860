import { FC } from 'react';
import { NONE_CATEGORY_ID, useNewsCategories } from 'store/news';
import { SelectFilter, SelectFilterProps } from 'components/filters';
import { useTranslation } from 'react-i18next';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const NewsCategoryFilter: FC<Props> = (props) => {
  const { t } = useTranslation();

  const { data: categories, isLoading } = useNewsCategories();

  return (
    <SelectFilter
      {...props}
      options={[
        ...(categories?.items ?? []),
        {
          id: NONE_CATEGORY_ID,
          name: t(`common.${NONE_CATEGORY_ID}`),
        },
      ].map((category) => ({
        key: category.id,
        label: category.name,
      }))}
      loadingOptions={isLoading}
      multiple={true}
    />
  );
};
