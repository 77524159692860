import { FC, useState } from 'react';
import { useBrands } from 'store/brands';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const BrandsFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: brands, isLoading } = useBrands({ search });

  return (
    <SelectFilter
      {...props}
      options={brands?.items.map((brand) => ({
        key: brand.id,
        label: brand.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
