import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './news.loaders';
import { converters } from './news.converters';
import {
  NewsCategoriesFilters,
  NewsCommentsFilters,
  NewsFilters,
} from './news.types';

export const newsKeys = queryKeyFactory('news');

export const useNews = (filters: Partial<NewsFilters> = {}) => {
  return useQuery({
    queryKey: newsKeys.list(filters),
    queryFn: () => loaders.getNews({ filters }),
    select: converters.getNews,
  });
};

export const useNew = (newId: string) => {
  return useQuery({
    queryKey: newsKeys.detail(newId),
    queryFn: () => loaders.getNew({ newId }),
    select: converters.getNew,
    enabled: !!newId,
  });
};

export const useNewsCategories = (
  filters: Partial<NewsCategoriesFilters> = {},
) => {
  return useQuery({
    queryKey: newsKeys.list('categories', filters),
    queryFn: () => loaders.getCategories({ filters }),
    select: converters.getCategories,
  });
};

export const useNewsCategory = (categoryId: string) => {
  return useQuery({
    queryKey: newsKeys.detail('categories', categoryId),
    queryFn: () => loaders.getCategory({ categoryId }),
    select: converters.getCategory,
    enabled: !!categoryId,
  });
};

export const useNewsComments = (filters: Partial<NewsCommentsFilters> = {}) => {
  return useQuery({
    queryKey: newsKeys.list('comments', filters),
    queryFn: () => loaders.getComments({ filters }),
    select: converters.getComments,
  });
};

export const useInfiniteNewsComments = (
  filters: Partial<NewsCommentsFilters> = {},
) => {
  return useInfiniteQuery(
    newsKeys.list('comments', 'infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getComments({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getComments(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );
};
