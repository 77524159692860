import { FC } from 'react';
import { CommentBlock } from 'melp-design/components';
import { t } from 'i18next';
import {
  Comment,
  useDeleteComments,
  useUpdateCommentsStatus,
} from 'store/comments';
import { usePredefinedToasts } from 'utils/Toast';
import { useConfirm } from 'store/confirm';
import { useMe } from 'state/Administrators';
import {
  EMOJI_BY_REACTION,
  useCreateReaction,
  useDeleteReaction,
} from 'store/reactions';
import { ReactionSelectModal } from 'containers/reactions';
import { useModalContext } from 'store/modal';
import { CommentEditModal } from 'containers/comments';

interface Props {
  comment: Comment;
}

export const CommentContainer: FC<Props> = ({ comment }) => {
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();
  const { confirmation } = useConfirm();
  const { me } = useMe();

  const {
    mutate: updateCommentsStatus,
    isLoading: isUpdatingStatus,
    variables: updateCommentVariables,
  } = useUpdateCommentsStatus();
  const {
    mutate: deleteComments,
    isLoading: isDeleting,
    variables: deleteCommentVariables,
  } = useDeleteComments();
  const {
    mutate: createReaction,
    isLoading: isCreatingReaction,
    variables: createReactionVariables,
  } = useCreateReaction();
  const {
    mutate: deleteReaction,
    isLoading: isDeletingReaction,
    variables: deleteReactionVariables,
  } = useDeleteReaction();

  return (
    <CommentBlock
      comment={comment}
      actions={[
        {
          ...(comment.reactions.selected
            ? {
                label: [
                  EMOJI_BY_REACTION[comment.reactions.selected],
                  t(`reactions.variant.${comment.reactions.selected}`),
                ].join(' '),
                onClick: () => {
                  deleteReaction({
                    hostId: comment.id,
                  });
                },
                variant: 'primary',
                loading:
                  isDeletingReaction &&
                  deleteReactionVariables?.hostId === comment.id,
              }
            : {
                label: t('actions.like'),
                onClick: async () => {
                  const response = await openModal({
                    component: ReactionSelectModal,
                    size: 'lg',
                  });

                  if (response.action === 'SELECT') {
                    createReaction({
                      entity: 'comment',
                      hostId: comment.id,
                      emoji: response.reaction,
                    });
                  }
                },
                loading:
                  isCreatingReaction &&
                  createReactionVariables?.hostId === comment.id,
              }),
        },
        ...(me?.id === comment.owner?.id
          ? [
              {
                label: t('common.edit'),
                onClick: () => {
                  openModal({
                    component: CommentEditModal,
                    props: {
                      commentId: comment.id,
                      initialComment: comment.content,
                    },
                  });
                },
              },
            ]
          : []),
        {
          ...(comment.status === 'visible'
            ? {
                label: t('common.hide'),
                onClick: () => {
                  updateCommentsStatus({
                    status: 'hidden',
                    ids: [comment.id],
                  });
                },
              }
            : {
                label: t('common.unhide'),
                onClick: () => {
                  updateCommentsStatus({
                    status: 'visible',
                    ids: [comment.id],
                  });
                },
              }),
          loading:
            isUpdatingStatus && updateCommentVariables?.ids[0] === comment.id,
        },
        {
          label: t('actions.delete'),
          onClick: async () => {
            const { confirmed } = await confirmation(
              t('comments.delete.message'),
            );

            if (confirmed) {
              deleteComments(
                { ids: [comment.id] },
                {
                  onSuccess: () => {
                    predefinedToasts.success.deleted();
                  },
                },
              );
            }
          },
          variant: 'danger',
          loading: isDeleting && deleteCommentVariables?.ids[0] === comment.id,
        },
      ]}
    />
  );
};
