import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { useCompanyLanguages } from 'state/Administrators';
import { loaders } from './news.loaders';
import { newsKeys } from './news.queries';

export const useCreateNew = () => {
  const queryClient = useQueryClient();
  const { defaultLanguage } = useCompanyLanguages();

  return useMutation({
    mutationFn: ({ name }: { name: string }) =>
      loaders.createNew({ name, defaultLanguage }),
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.lists);
    },
  });
};

export const useUpdateNew = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateNew, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useCopyNew = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.copyNew, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.lists);
    },
  });
};

export const useRemoveNew = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteNew, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.lists);
    },
  });
};

export const useBulkUpdateNews = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkUpdateNews, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useBulkDeleteNews = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkDeleteNews, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};

export const useCreateNewsCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createCategory, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.list('categories'));
    },
  });
};

export const useUpdateNewsCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCategory, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(newsKeys.detail('categories')),
        queryClient.invalidateQueries(newsKeys.list('categories')),
      ]);
    },
  });
};

export const useRemoveNewsCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteCategory, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.list('categories'));
    },
  });
};
