import { FC, useMemo } from 'react';
import { InfiniteList } from 'melp-design/components';
import {
  recognitionsCommentsFiltersSchema,
  useInfiniteRecognitionsComments,
} from 'store/recognitions';
import { CommentContainer } from 'containers/comments';

interface Props {
  recognitionId: string;
}

export const CommentsFeed: FC<Props> = ({ recognitionId }) => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteRecognitionsComments({
      ...recognitionsCommentsFiltersSchema.parse({}),
      recognitions: [recognitionId],
    });

  const comments = useMemo(() => {
    return data
      ? {
          items: (data.pages.map(({ items }) => items) ?? []).flat(),
          total: data.pages[0]?.total,
        }
      : null;
  }, [data]);

  return (
    <InfiniteList
      data={comments?.items}
      renderItem={(comment) => <CommentContainer comment={comment} />}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
    />
  );
};
