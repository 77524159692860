import { FC, useState } from 'react';
import { Modal, Typography } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import { shopItemCategories } from 'types/MarketplaceItems';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import { Stack } from '@mui/material';
import { FilterButton, Filters, SelectFilter } from 'components/filters';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import { isEqual } from 'lodash';
import {
  BenefitBrandsConfig,
  useBenefitBrands,
  useRemoveBenefitBrandsConfig,
  useUpdateBenefitBrandsConfig,
} from 'store/benefit-brands';
import { brandProviders } from 'store/brands';

type Values = Pick<
  BenefitBrandsConfig,
  'providers' | 'primaryCategories' | 'countries' | 'price'
>;

const INITIAL_VALUES: Values = {
  providers: [],
  primaryCategories: [],
  countries: [],
  price: null,
};

interface Props extends ModalProps {
  benefitId: string;
  config: Values;
}

export const RewardsConfigModal: FC<Props> = ({
  closeModal,
  benefitId,
  config,
}) => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const [filters, setFilters] = useState<Values>(config);

  const { data: rewards, isLoading } = useBenefitBrands(benefitId, filters);

  const { mutate: updateConfig, isLoading: isUpdating } =
    useUpdateBenefitBrandsConfig();
  const { mutate: removeConfig, isLoading: isRemoving } =
    useRemoveBenefitBrandsConfig();

  return (
    <Modal.Content
      title={t('recognitions.rewards.actions.set_config')}
      actions={[
        {
          variant: 'danger-outline',
          onClick: () => {
            removeConfig(
              { benefitId },
              {
                onSuccess: () => {
                  closeModal({ action: 'CLOSE' });
                  predefinedToasts.success.updated();
                },
              },
            );
          },
          label: t('recognitions.rewards.actions.remove_config'),
          disabled: isRemoving,
        },
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        ...(isEqual(config, filters)
          ? []
          : [
              {
                variant: 'primary',
                onClick: () => {
                  updateConfig(
                    {
                      benefitId,
                      config: {
                        providers: filters.providers,
                        primaryCategories: filters.primaryCategories,
                        countries: filters.countries,
                        price: filters.price,
                      },
                    },
                    {
                      onSuccess: () => {
                        closeModal({ action: 'CLOSE' });
                        predefinedToasts.success.updated();
                      },
                    },
                  );
                },
                label: t('marketplace.confirmSettings', {
                  count: rewards?.total,
                }),
                disabled: isLoading || isUpdating,
              } as const,
            ]),
      ]}
    >
      <Stack gap={2}>
        <Typography variant="p1" color="textSecondary">
          {t('recognitions.rewards.config.description')}
        </Typography>

        <Filters
          value={filters}
          initialValues={INITIAL_VALUES}
          onChange={setFilters}
        >
          {({ value, initialValues, setFilter }) => (
            <>
              <FilterButton
                label={t('providers.title')}
                value={value.providers}
                initialValue={initialValues.providers}
                onChange={(v) => setFilter('providers', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="providers"
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    multiple={true}
                    searchable={false}
                    options={brandProviders.map((provider) => ({
                      key: provider,
                      label: provider,
                    }))}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('brands.primary_category.label')}
                value={value.primaryCategories}
                initialValue={initialValues.primaryCategories}
                onChange={(v) => setFilter('primaryCategories', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="primaryCategories"
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    multiple={true}
                    searchable={false}
                    options={shopItemCategories.map((category) => ({
                      key: category,
                      label: t(`marketplaceItems.itemCategory-${category}`),
                    }))}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('benefits.country')}
                value={value.countries}
                initialValue={initialValues.countries}
                onChange={(v) => setFilter('countries', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <SelectFilter
                    name="countries"
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                    multiple
                    options={APP.countries.alpha3.map((alpha3) => ({
                      key: alpha3,
                      label: formatCountry(i18n.language, alpha3),
                    }))}
                  />
                )}
              </FilterButton>

              <FilterButton
                label={t('marketplace.filtersPriceRange')}
                value={value.price}
                initialValue={initialValues.price}
                onChange={(v) => setFilter('price', v)}
              >
                {({ value, applyFilter, clearFilter, close }) => (
                  <NumberRangeFilter
                    value={value}
                    onApplyFilter={applyFilter}
                    onClearFilter={clearFilter}
                    onCancel={close}
                  />
                )}
              </FilterButton>
            </>
          )}
        </Filters>
      </Stack>
    </Modal.Content>
  );
};
