import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import {
  RecognitionsCommentsFilters,
  RecognitionsFilters,
} from './recognitions.types';
import { loaders } from './recognitions.loaders';
import { converters } from './recognitions.converters';

export const recognitionsKeys = queryKeyFactory('recognitions');

export const useRecognitions = (filters: Partial<RecognitionsFilters> = {}) => {
  return useQuery({
    queryKey: recognitionsKeys.list(filters),
    queryFn: () => loaders.getRecognitions({ filters }),
    select: converters.getRecognitions,
  });
};

export const useRecognition = (recognitionId: string) => {
  return useQuery({
    queryKey: recognitionsKeys.detail(recognitionId),
    queryFn: () => loaders.getRecognition({ recognitionId }),
    select: converters.getRecognition,
    enabled: !!recognitionId,
  });
};

export const useRecognitionsSettings = () => {
  return useQuery({
    queryKey: recognitionsKeys.detail('settings'),
    queryFn: () => loaders.getRecognitionsSettings(),
    select: converters.getRecognitionsSettings,
    staleTime: 1000,
  });
};

export const useRecognitionsComments = (
  filters: Partial<RecognitionsCommentsFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionsKeys.list('comments', filters),
    queryFn: () => loaders.getComments({ filters }),
    select: converters.getComments,
  });
};

export const useInfiniteRecognitionsComments = (
  filters: Partial<RecognitionsCommentsFilters> = {},
) => {
  return useInfiniteQuery(
    recognitionsKeys.list('comments', 'infinite', filters),
    ({ pageParam = 1 }) =>
      loaders.getComments({
        filters: { ...filters, page: pageParam },
      }),
    {
      getNextPageParam: ({ data }, allPages) => {
        const totalPages = Math.ceil(data.count / data.pageSize);
        const currentPage = allPages.length;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: ({ pages, pageParams }) => ({
        pages: pages.map((page) => converters.getComments(page)),
        pageParams,
      }),
      keepPreviousData: true,
    },
  );
};
