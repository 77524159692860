import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IBaseBrand, IBrand, IBrandWithSelection } from 'types/income';
import { getSorting } from 'utils/general';
import { BrandsFilters } from './brands.types';

const getFilters = (filters: Partial<BrandsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    type: filters.variant,
    brandIds: filters.brands,
    provider: filters.providers,
    country: filters.countries,
    primaryCategory: filters.primaryCategories,
    priceFrom: filters.price?.from,
    priceTo: filters.price?.to,
    isSelected:
      filters.selection === 'selected'
        ? true
        : filters.selection === 'not-selected'
        ? false
        : undefined,
  },
});

interface GetBrands {
  filters: Partial<BrandsFilters>;
}

interface GetBrand {
  brandId: string;
}

interface AddBrand {
  brandId: string;
}

interface RemoveBrand {
  brandId: string;
}

interface BulkAddBrands {
  filters: Partial<BrandsFilters>;
}

interface BulkRemoveBrands {
  filters: Partial<BrandsFilters>;
}

export const loaders = {
  getBrands: ({ filters }: GetBrands) => {
    return api<TableDataResponse<IBaseBrand & IBrandWithSelection>>({
      url: Endpoints.clientAdmin.brands.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getBrand: ({ brandId }: GetBrand) => {
    return api<IBrand>({
      url: Endpoints.clientAdmin.brands.byId.root.replace(':brandId', brandId),
    });
  },
  addBrand: ({ brandId }: AddBrand) => {
    return api({
      url: Endpoints.clientAdmin.brands.selection.byId.replace(
        ':brandId',
        brandId,
      ),
      method: 'post',
    });
  },
  removeBrand: ({ brandId }: RemoveBrand) => {
    return api({
      url: Endpoints.clientAdmin.brands.selection.byId.replace(
        ':brandId',
        brandId,
      ),
      method: 'delete',
    });
  },
  bulkAddBrands: ({ filters }: BulkAddBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.brands.selection.bulk,
      params: availableFilters,
      method: 'post',
    });
  },
  bulkRemoveBrands: ({ filters }: BulkRemoveBrands) => {
    const { page, pageSize, sorting, direction, ...availableFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.brands.selection.bulk,
      params: availableFilters,
      method: 'delete',
    });
  },
};
