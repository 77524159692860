import { useRef, useState } from 'react';
import { Typography } from 'melp-design/components';
import Button from '../ButtonOld/Button';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { GMT_OFFSETS } from './gmt-offsets';

const createOffsetLabel = (offset: string) => `GMT${offset}`;

interface Props {
  /**
   * Offset as string (e.g., "+03:00").
   */
  value?: string;
  /**
   * Callback to execute when offset changes.
   */
  onChange: (value: string) => void;
  /**
   * Disables the picker.
   */
  disabled?: boolean;
}

const TimeOffsetPicker = (props: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const currentLabel = props.value ? createOffsetLabel(props.value) : '';

  const getButtonLabel = () => {
    if (!currentLabel) {
      return '';
    }

    // Requires short version for space saving
    if (currentLabel.endsWith(':00')) {
      return currentLabel.slice(0, -3);
    }

    return currentLabel;
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        ref={anchorRef}
        disabled={props.disabled}
        style={{ padding: '11px 5px' }}
      >
        <Typography variant="p1" color="textSecondary" whiteSpace="nowrap">
          {getButtonLabel()}
        </Typography>
      </Button>
      <DropdownMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        menuItems={GMT_OFFSETS.map((offset) => ({
          key: offset,
          label: createOffsetLabel(offset),
        }))}
        onItemClick={(item) => {
          props.onChange(item.key);
          setOpen(false);
        }}
        renderItemLabel={(item) => (
          <Typography
            color={item.label === currentLabel ? 'primary' : 'textPrimary'}
          >
            {item.label}
          </Typography>
        )}
        maxHeight={200}
      />
    </>
  );
};

export default TimeOffsetPicker;
