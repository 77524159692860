import { Colors, SystemColors } from 'melp-design/style';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import { Typography } from 'melp-design/components';
import { formatBytes } from 'utils/format';
import { getBorder } from '../../../constants/Style';
import { toPropsValue, toQuillValue } from './converters';
import { quillModules } from './quill-setup';

const DEFAULT_SIZE_LIMIT_IN_BYTES = 8 * 1024 * 1024; // 8MB

function calculateBytes(html: string = ''): number {
  const encoder = new TextEncoder();
  const encodedBytes = encoder.encode(html);
  return encodedBytes.length;
}

const isQuillEmpty = (quill: ReactQuill.UnprivilegedEditor) => {
  if ((quill.getContents()['ops'] || []).length !== 1) {
    return false;
  }
  return quill.getText().trim().length === 0;
};

interface StyleParams {
  minHeight: string | number;
  maxHeight: string | number;
}

const useStyles = makeStyles((theme) => ({
  richTextEditor: ({ minHeight, maxHeight }: StyleParams) => ({
    color: SystemColors.grey[45],
    '& .ql-toolbar.ql-snow': {
      border: getBorder(Colors.elementsGrey),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
    },
    '& .ql-container.ql-snow': {
      border: getBorder(Colors.elementsGrey),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      fontFamily: '"Inter", sans-serif',
    },
    '& .ql-editor': {
      minHeight,
      maxHeight,
    },
  }),
  displayMode: {
    '&.quill .ql-toolbar.ql-snow': {
      display: 'none',
    },
    '&.quill .ql-container.ql-snow': {
      border: 'none',
      background: 'none',
    },
    '&.quill .ql-editor': {
      padding: 0,
      minHeight: 'unset',
      maxHeight: 'unset',
    },
  },
  error: {
    '&.quill .ql-toolbar.ql-snow': {
      borderTopColor: Colors.red,
      borderLeftColor: Colors.red,
      borderRightColor: Colors.red,
    },
    '&.quill .ql-container.ql-snow': {
      borderColor: Colors.red,
    },
  },
}));

interface Props {
  /**
   * Input value. Should be HTML string.
   */
  value?: string;
  /**
   * Input value change event callback.
   */
  onChange?: (value: string) => void;
  /**
   * Input placeholder.
   */
  placeholder?: string;
  /**
   * CSS class added to the root element.
   */
  className?: string;
  /**
   * ID for HTML element.
   */
  id?: string;
  /**
   * Indicates whether "display mode" is active. It removes toolbar and editing
   * capabilities. Only formatted text is displayed.
   */
  displayMode?: boolean;
  /**
   * Indicates whether input is disabled.
   */
  disabled?: boolean;
  /**
   * Min height of editor
   */
  minHeight?: string | number;
  /**
   * Max height of editor
   */
  maxHeight?: string | number;
  /**
   * Applies error state style
   */
  error?: boolean;
}

/**
 * Input for creating a rich text. It is based on Quill rich text editor.
 */
const RichTextEditor = forwardRef<ReactQuill, Props>(
  (
    {
      className,
      displayMode = false,
      disabled = false,
      onChange,
      value,
      minHeight = 100,
      maxHeight = 300,
      error,
      placeholder,
      id,
    },
    ref,
  ) => {
    const classes = useStyles({ minHeight, maxHeight });
    const handleChange: ReactQuill.ReactQuillProps['onChange'] = (
      newValue,
      _delta,
      source,
      editor,
    ) => {
      // React only to changes initiated by a user.
      // See the github issue for more details:
      //   https://github.com/zenoamaro/react-quill/issues/259
      if (source === 'user') {
        if (isQuillEmpty(editor)) {
          onChange?.('');
        } else {
          onChange?.(toPropsValue(newValue));
        }
      }
    };

    const currentSize = calculateBytes(value);
    const exceedingLimit = currentSize > DEFAULT_SIZE_LIMIT_IN_BYTES;

    return (
      <div>
        <ReactQuill
          ref={ref}
          id={id}
          placeholder={placeholder}
          theme="snow"
          modules={quillModules}
          className={clsx(
            classes.richTextEditor,
            displayMode && classes.displayMode,
            error && classes.error,
            className,
          )}
          readOnly={disabled || displayMode}
          value={toQuillValue(value)}
          onChange={handleChange}
        />
        {displayMode ? null : (
          <Typography
            variant="p2"
            color={exceedingLimit ? 'error' : 'textSecondary'}
            mt="5px"
            align="right"
          >
            {[currentSize, DEFAULT_SIZE_LIMIT_IN_BYTES]
              .map(formatBytes)
              .join(' / ')}
          </Typography>
        )}
      </div>
    );
  },
);

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
