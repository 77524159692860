import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { z } from 'zod';
// Full import path needed for webpack to correctly resolve circular dependency starting storybook
import { commentStatuses } from 'store/comments/comments.types';
import { reactionsVariants } from 'store/reactions/reactions.types';
import { recognitionTypes } from './recognitions.types';

export const recognitionsFiltersSchema = z
  .object({
    type: z.enum(recognitionTypes).nullable().catch(null),
    achievements: z.array(z.string()).catch([]),
    employee: z.string().catch(''),
    sender: z.array(z.string()).catch([]),
    recipient: z.array(z.string()).catch([]),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);

export const recognitionsCommentsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    reactions: z.array(z.enum(reactionsVariants)).catch([]),
    status: z.array(z.enum(commentStatuses)).catch([]),
    date: dateRangeSchema,
    employees: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    sender: z.array(z.string()).catch([]),
    recipient: z.array(z.string()).catch([]),
    recognitions: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
