import { FC } from 'react';
import {
  CommentsPreview,
  Loader,
  Modal,
  NothingFoundAlert,
  ReactionsPreview,
  TextField,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps, useModalContext } from 'store/modal';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { ReactionsModal } from 'containers/reactions';
import { Controller, useForm } from 'react-hook-form-latest';
import { SystemColors } from 'melp-design/style';
import { useCreateComment } from 'store/comments';
import { useRecognition, useRecognitionsSettings } from 'store/recognitions';
import { CommentsFeed } from './atoms';

interface Values {
  comment: string;
}

interface Props extends ModalProps {
  recognitionId: string;
}

export const RecognitionThreadModal: FC<Props> = ({
  recognitionId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const { data: recognition, isLoading } = useRecognition(recognitionId);
  const { data: settings } = useRecognitionsSettings();

  const {
    control,
    getValues,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: {
      comment: '',
    },
  });

  const { mutate: createComment, isLoading: isCreatingComment } =
    useCreateComment();

  if (isLoading) {
    return (
      <Modal.Content>
        <Loader />
      </Modal.Content>
    );
  }
  if (!recognition) {
    return (
      <Modal.Content>
        <NothingFoundAlert />
      </Modal.Content>
    );
  }

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <Modal.Content
      title={[
        recognition.sender?.fullName || t('marketplace.employeeDeleted'),
        recognition.employee?.fullName || t('marketplace.employeeDeleted'),
      ].join(' ▸ ')}
      dividers
      header={
        <Stack
          flexDirection="row"
          columnGap={4}
          rowGap={1}
          alignItems="center"
          flexWrap="wrap"
        >
          {recognition.createdAt ? (
            <Typography color="textSecondary">
              {moment(recognition.createdAt).format('l LT')}
            </Typography>
          ) : null}
          {recognition.commentsCount ? (
            <CommentsPreview count={recognition.commentsCount} />
          ) : null}
          {recognition.reactions.total ? (
            <ReactionsPreview
              reactions={recognition.reactions.summary}
              onClick={() => {
                openModal({
                  component: ReactionsModal,
                  props: { hostId: recognition.id },
                });
              }}
            />
          ) : null}
        </Stack>
      }
      footer={
        settings?.allowComments ? (
          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('clients.comment')}
                name={name}
                value={value}
                onChange={onChange}
                rows={2}
                sx={{ width: '100%' }}
                ref={ref}
              />
            )}
          />
        ) : null
      }
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('buttons.ok'),
        },
        ...(settings?.allowComments
          ? [
              {
                variant: 'primary',
                label: t('common.submit'),
                onClick: () => {
                  createComment(
                    {
                      entity: 'recognition',
                      subjectId: recognition.id,
                      content: getValues('comment'),
                    },
                    {
                      onSuccess: () => {
                        reset({ comment: '' }, { keepDirty: false });
                      },
                    },
                  );
                },
                disabled: isCreatingComment || !isDirty,
              } as const,
            ]
          : []),
      ]}
    >
      <Stack gap={2} alignItems="flex-start">
        {recognition.image?.url ? (
          <img
            src={recognition.image.url}
            style={{ maxHeight: 300, maxWidth: '100%' }}
            alt=""
          />
        ) : null}

        <Box sx={{ py: 1 }}>
          <Typography>{recognition.comment}</Typography>
        </Box>
      </Stack>

      <Stack
        flexDirection="row"
        alignItems="center"
        gap={2}
        sx={{
          py: 1,
          my: 1,
          borderBottom: `1px solid ${SystemColors.grey[90]}`,
        }}
      >
        <Typography variant="h3">
          {t('comments.title')} ({recognition.commentsCount})
        </Typography>

        {recognition.reactions.total ? (
          <ReactionsPreview
            reactions={recognition.reactions.summary}
            onClick={() => {
              openModal({
                component: ReactionsModal,
                props: { hostId: recognition.id },
              });
            }}
          />
        ) : null}
      </Stack>

      <CommentsFeed recognitionId={recognition.id} />
    </Modal.Content>
  );
};
