import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './companies.loaders';
import { converters } from './companies.converters';
import { ParentCompaniesFilters } from './companies.types';

export const companiesKeys = queryKeyFactory('companies');

export const useCompanies = () => {
  return useQuery({
    queryKey: companiesKeys.list(),
    queryFn: () => loaders.getCompanies(),
    select: converters.getCompanies,
  });
};

export const useCompany = (companyId: string) => {
  return useQuery({
    queryKey: companiesKeys.detail(companyId),
    queryFn: () => loaders.getCompany({ companyId }),
    select: converters.getCompany,
    enabled: !!companyId,
  });
};

export const useCompanyContacts = (companyId: string) => {
  return useQuery({
    queryKey: companiesKeys.list('contacts', companyId),
    queryFn: () => loaders.getCompanyContacts({ companyId }),
    select: converters.getCompanyContacts,
    enabled: !!companyId,
  });
};

export const useParentCompanies = (
  filters: Partial<ParentCompaniesFilters> = {},
) => {
  return useQuery({
    queryKey: companiesKeys.list('parents', filters),
    queryFn: () => loaders.getParentCompanies({ filters }),
    select: converters.getParentCompanies,
  });
};

export const useDepartments = () => {
  return useQuery({
    queryKey: companiesKeys.list('departments'),
    queryFn: () => loaders.getDepartments(),
    select: converters.getDepartments,
  });
};
