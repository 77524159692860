import { ButtonBase, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { SystemColors } from 'melp-design/style';
import { Typography } from 'melp-design/components';

interface TabConfig<Tab> {
  key: Tab;
  label: string;
  url?: string;
  isActive?: boolean;
  disabled?: boolean;
}

interface Props<Tab> {
  activeTab?: Tab;
  onTabChange?: (tabKey: Tab) => void;
  tabs: TabConfig<Tab>[];
  readOnly?: boolean;
}

export const SideMenu = <Tab extends string>({
  activeTab,
  tabs,
  onTabChange,
  readOnly,
}: Props<Tab>) => {
  return (
    <Paper
      variant="outlined"
      sx={{ p: 1, borderRadius: 1, minWidth: 175, maxWidth: 250 }}
    >
      {tabs.map((tab) => {
        const isActive = tab.isActive ?? tab.key === activeTab;

        return (
          <ButtonBase
            {...(tab.url
              ? {
                  component: Link,
                  to: tab.url,
                  disabled: readOnly || tab.disabled,
                }
              : {
                  onClick: () => onTabChange?.(tab.key),
                  disabled: readOnly || tab.disabled,
                })}
            sx={{
              display: 'block',
              width: '100%',
              color: SystemColors.grey[90],
              py: 1.5,
              px: 2,
              borderRadius: 1,
              backgroundColor: isActive
                ? SystemColors.primary.tint
                : 'transparent',
              transition: 'background-color 100ms ease-in-out',
            }}
            key={tab.key}
          >
            <Typography
              color={isActive ? 'primary' : 'textSecondary'}
              align="left"
            >
              {tab.label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Paper>
  );
};
