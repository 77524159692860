import { FC } from 'react';
import {
  Button,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useRouteParams } from 'utils/useIdParam';
import { useSearchParams } from 'utils/navigation';
import {
  adminDepositEventsFiltersSchema,
  useAdminDeleteDepositEvent,
  useAdminDepositEvents,
} from 'store/admin-client-deposits';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import { useConfirm } from 'store/confirm';
import { CreateDepositEventModal, EditDepositEventModal } from './atoms';

export const AdminClientDepositEventsList: FC = () => {
  const { t } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.depositEvents.list,
  );
  const { openModal } = useModalContext();
  const { confirmation } = useConfirm();

  const { searchParams: filters, navigate } = useSearchParams(
    adminDepositEventsFiltersSchema,
  );

  const { data: events, isLoading } = useAdminDepositEvents({
    ...filters,
    client: [clientId],
  });

  const { mutate: deleteDepositEvent } = useAdminDeleteDepositEvent();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack flexDirection="row" justifyContent="flex-end">
            <Button
              label={t('common.add')}
              onClick={() => {
                openModal({
                  component: CreateDepositEventModal,
                  props: { clientId, expenseType: filters.type[0] },
                });
              }}
            />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={events?.items.length} />

            <Stack direction="row" alignItems="center" gap="10px">
              {/* Filters */}
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={events?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'from',
            header: t('common.from'),
            cell: ({ row: { original: event } }) => (
              <Stack>
                <Typography>
                  {moment(event.startsAt.date).format('l LT')}
                </Typography>
                <Typography>{`${t('common.done_by')} ${
                  event.startsAt.admin.name
                }`}</Typography>
              </Stack>
            ),
          },
          {
            id: 'to',
            header: t('common.to'),
            cell: ({ row: { original: event } }) =>
              event.endsAt ? (
                <Stack>
                  <Typography>
                    {moment(event.endsAt.date).format('l LT')}
                  </Typography>
                  <Typography>{`${t('common.done_by')} ${
                    event.endsAt.admin.name
                  }`}</Typography>
                </Stack>
              ) : (
                '-'
              ),
          },
          {
            id: 'actions',
            cell: ({ row: { original: event } }) => (
              <Stack flexDirection="row" gap={1} justifyContent="flex-end">
                <Button
                  label={t('common.edit')}
                  variant="neutral-outline"
                  size="sm"
                  onClick={() => {
                    openModal({
                      component: EditDepositEventModal,
                      props: {
                        eventId: event.id,
                        initialValues: {
                          from: event.startsAt.date,
                          to: event.endsAt?.date ?? null,
                        },
                      },
                    });
                  }}
                />
                <Button
                  label={t('actions.delete')}
                  variant="danger-outline"
                  size="sm"
                  onClick={async () => {
                    const { confirmed } = await confirmation(
                      t('common.areYouSureToDelete', {
                        title: t('alternatives.dateRange'),
                      }),
                    );

                    if (confirmed) {
                      deleteDepositEvent({ eventId: event.id });
                    }
                  }}
                />
              </Stack>
            ),
          },
        ]}
        pagination={
          events && {
            count: events.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
