import { useContext, useState } from 'react';
import {
  Alert,
  ButtonOld,
  DetailsPageContent,
  DetailsPageHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Form,
  FormField,
  Loader,
  Menu,
  NothingFoundAlert,
  SideMenu,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import {
  BenefitBrandsList,
  BenefitEmployeesAssignments,
  BenefitEmployeesGroupsAssignments,
  BenefitsDisabledFeatureAlert,
} from 'containers/benefits';
import { tabsSchema } from 'utils/schemas';
import SingleBenefitPDFDownloader from 'components/benefits/BenefitsPDF/SingleBenefitPDFDownloader';
import { useMe } from 'state/Administrators';
import { useBenefit, useBenefitMutations } from 'state/Benefits';
import { Benefit, BenefitCopyRequest } from 'types/Benefits';
import { useDialog } from 'utils/Dialog';
import { useTabsState } from 'utils/Tabs';
import { createTabsContext } from 'utils/TabsContext';
import { isDefined } from 'utils/isDefined';
import { useRouteParams } from 'utils/useIdParam';
import { usePredefinedToasts } from 'utils/Toast';
import { useConfirm } from 'store/confirm';
import { COLOR_BY_BENEFIT_STATUS } from 'store/benefits';
import BenefitInfoPanel from './common/BenefitInfoPanel';
import { UnsavedChangesContextProvider } from './common/unsaved-changes/UnsavedChangesContext';
import Content from './content/Content';
import Marketplace from './marketplace/Marketplace';
import Settings from './settings/Settings';
import { resolveBenefitValidityStatus } from './common/resolveBenefitValidityStatus';

const EXPIRATION_WARNING_DAYS = 15;

const getDaysUntilExpiration = (deactivationDate: Benefit['deactivatedAt']) => {
  const now = moment();

  if (!deactivationDate || now.isAfter(deactivationDate)) {
    return;
  }

  return Math.round(moment(deactivationDate).diff(now, 'days', true));
};

const benefitDetailsTabsSchema = tabsSchema([
  'settings',
  'content',
  'marketplace',
  'brands',
  'employeesGroupsAssignment',
  'employeesAssignment',
]);

type BenefitDetailsTabs =
  keyof typeof benefitDetailsTabsSchema.shape.tab.Values;

const { TabsContext } = createTabsContext<
  BenefitDetailsTabs,
  typeof benefitDetailsTabsSchema
>();
export const useTabsContext = () => {
  const tabsContext = useContext(TabsContext);
  if (!tabsContext) {
    throw Error('Context provider is missing');
  }
  return tabsContext;
};

const BenefitDetailsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const predefinedToasts = usePredefinedToasts();
  const { id } = useRouteParams(ROUTES.benefits.details);
  const { confirmation } = useConfirm();
  const { canEditBenefits, isFeatureEnabled } = useMe();

  const sideTabs = useTabsState(
    [
      {
        key: 'settings',
        label: t('common.settings'),
      },
      {
        key: 'content',
        label: t('benefitTemplates.templateContent'),
      },
      isFeatureEnabled('marketplaceV2')
        ? {
            key: 'brands',
            label: t('sidebar.marketplace'),
          }
        : {
            key: 'marketplace',
            label: t('sidebar.marketplace'),
          },
      {
        key: 'employeesGroupsAssignment',
        label: t('sidebar.assignGroups'),
      },
      {
        key: 'employeesAssignment',
        label: t('sidebar.assignEmployees'),
      },
    ],
    benefitDetailsTabsSchema,
  );

  const benefit = useBenefit(id);
  const benefitMutations = useBenefitMutations(id);

  const handleDeleteBenefit = async () => {
    await benefitMutations.remove.execute();
    predefinedToasts.success.deleted();
    history.push(ROUTES.benefits.list);
  };

  const copyDialog = useDialog();
  const handleCopy = async (data: BenefitCopyRequest) => {
    const response = await benefitMutations.copy.execute(data);
    copyDialog.closeDialog();
    history.push(ROUTES.benefits.details.replace(':id', response.id));
  };

  const pdfDialog = useDialog();

  const [showExpirationWarning, setShowExpirationWarning] = useState(true);

  if (benefit.loading) return <Loader />;
  if (!benefit.data) return <NothingFoundAlert />;

  const copyTitle = t('common.copy');
  const copyPrefix = `(${
    copyTitle.charAt(0).toUpperCase() + copyTitle.slice(1)
  })`;

  const daysUntilExpiration = getDaysUntilExpiration(
    benefit.data.deactivatedAt,
  );
  const expiresSoon =
    isDefined(daysUntilExpiration) &&
    daysUntilExpiration <= EXPIRATION_WARNING_DAYS;

  const validityStatus = resolveBenefitValidityStatus(benefit.data);

  return (
    <UnsavedChangesContextProvider>
      <Stack gap={3}>
        <BenefitsDisabledFeatureAlert />

        {expiresSoon && showExpirationWarning && (
          <Alert
            severity="warning"
            onClose={() => setShowExpirationWarning(false)}
          >
            {t('benefits.expiresSoonWarning', {
              count: daysUntilExpiration,
            })}
          </Alert>
        )}

        <Stack>
          <DetailsPageHeader
            backTo={ROUTES.benefits.list}
            side={
              <>
                <Menu
                  label={t('common.actions')}
                  items={[
                    {
                      label: t('common.downloadPDF'),
                      onClick: () => pdfDialog.openDialog(),
                    },
                    ...(canEditBenefits
                      ? [
                          {
                            label: t('actions.make_copy'),
                            onClick: () => copyDialog.openDialog(),
                          },
                          {
                            label: t('actions.delete'),
                            onClick: async () => {
                              const { confirmed } = await confirmation(
                                t('benefits.deleteWarning'),
                              );

                              if (confirmed) {
                                handleDeleteBenefit();
                              }
                            },
                            disabled: benefitMutations.remove.loading,
                          },
                        ]
                      : []),
                  ]}
                />

                <SingleBenefitPDFDownloader
                  open={pdfDialog.open}
                  onCancel={pdfDialog.closeDialog}
                />
                <Dialog
                  width={450}
                  open={copyDialog.open}
                  onClose={copyDialog.closeDialog}
                >
                  <Form onSubmit={handleCopy}>
                    <DialogTitle>{t('actions.make_copy')}</DialogTitle>
                    <DialogContent>
                      <FormField
                        name="name"
                        label={t('common.systemName')}
                        render="text"
                        rules={{ required: true }}
                        defaultValue={`${copyPrefix} ${benefit.data.name}`}
                      />
                    </DialogContent>
                    <DialogActions>
                      <ButtonOld onClick={copyDialog.closeDialog}>
                        {t('common.cancel')}
                      </ButtonOld>
                      <ButtonOld
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t('common.create')}
                      </ButtonOld>
                    </DialogActions>
                  </Form>
                </Dialog>
              </>
            }
            title={benefit.data.name}
            under={
              <Typography color={COLOR_BY_BENEFIT_STATUS[validityStatus]}>
                {t(`status.${validityStatus}`)}
              </Typography>
            }
          />

          <DetailsPageContent>
            <Stack gap="10px">
              <BenefitInfoPanel />
              <SideMenu
                activeTab={sideTabs.activeTab}
                onTabChange={sideTabs.goTo}
                tabs={sideTabs.tabs}
              />
            </Stack>
            <TabsContext.Provider value={sideTabs}>
              {sideTabs.activeTab === 'settings' && <Settings />}
              {sideTabs.activeTab === 'content' && <Content />}
              {sideTabs.activeTab === 'employeesGroupsAssignment' && (
                <BenefitEmployeesGroupsAssignments />
              )}
              {sideTabs.activeTab === 'employeesAssignment' && (
                <BenefitEmployeesAssignments />
              )}
              {sideTabs.activeTab === 'marketplace' && <Marketplace />}
              {sideTabs.activeTab === 'brands' && <BenefitBrandsList />}
            </TabsContext.Provider>
          </DetailsPageContent>
        </Stack>
      </Stack>
    </UnsavedChangesContextProvider>
  );
};

export default BenefitDetailsPage;
